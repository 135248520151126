import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { localStorageGet } from "./LocalStorageHelper";

const defaultLanguage = localStorageGet("language");

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  lng: defaultLanguage || "sr-RS-Cyrl",
  fallbackLng: "sr_RS_Latn",
  returnNull: false,
  debug: true,
  resources: {
    "sr-RS-Latn": {
      translation: {
        ////// BASIC //////
        Yes: "Da",
        No: "Ne",
        Back: "Nazad",
        Confirm: "Potvrdi",
        Next: "Dalje",
        Ok: "U redu",
        Attachments: "Prilozi",
        Cancel: "Otkaži",
        Edit: "Izmeni",
        Delete: "Ukloni",
        Remove: "Izbriši",
        Search: "Pretraži",
        or: "ili",
        NumberShort: "Br.",
        TryAgain: "Pokušajte ponovo",
        Important: "VAŽNA INFORMACIJA!",
        eRPGLong: "Elektronski registar poljoprivrednih gazdinstava",
        FooterFreeCall: "Besplatan poziv za podršku:",
        PENDING: "U pripremi",
        PENDING_APPROVAL: "Čeka odobrenje",
        ACTIVE: "Aktivno",
        PASSIVE: "Pasivno",
        DELETED: "Obrisano iz registra",
        COMMERCIAL: "Komercijalno gazdinstvo",
        NON_COMMERCIAL: "Nekomercijalno gazdinstvo",
        M: "Muški",
        F: "Ženski",
        CONVENTIONAL: "Konvencionalna",
        ORGANIC: "Organska",
        FreeTextPlaceholder: "Slobodan tekst...",
        ChoosePlaceholder: "Izaberi...",
        Title: "Naslov",
        Note: "Napomena",
        Save: "Sačuvaj",
        MissingAttachment: "Nedostaje prilog",
        ContinueWithoutFarm: "Nastavi bez izbora poljoprivrednog gazdinstva",
        MobileAppUnavailable:
          "Pristup eRPG je trenutno omogućen preko tablet uređaja i računara",
        RequiredFieldsMissing: "Nisu uneti podaci za sva obavezna polja.",
        RequiredFieldError: "Morate popuniti polje",
        Statement: "Izjava saglasnosti",
        NoAccessInfo: "Nemate pravo pristupa ovoj stranici",
        CancelRequestInfo: "Vaš zahtev je otkazan",
        ReturnToDashboard: "Vratite se na početnu stranu",
        CreatedAtTime: "Vreme kreiranja",
        Actions: "Akcije",
        Details: "Detalji",
        Quit: "Odustani",
        Supplier: "Dobavljač",
        SupplierForm: "Pravna forma dobavljača",

        ////// HELP //////
        HelpTitle: "Pomoć za korisnike",
        HelpDesc:
          "Pogledajte video prezentaciju ili pročitajte ilustrovano uputstvo za poceduru izmene podataka i obnovu poljoprivrednog gazdinstva.",
        HelpTEXT: "Pročitajte uputstvo",
        HelpVIDEO: "Pogledajte kratko video uputstvo",
        HelpAQ1:
          "<strong>Setvenu strukturu je potrebno uneti za sve parcele na kojima vršite uzgoj. Sasvim je u redu što vam se trenutno ne prikazuje nijedna biljna kultura, ovi podaci nisu preuzeti iz starog Regristra.</strong>",
        HelpAQ2:
          "<strong>Ako ne vidite parcelu u RPG</strong>, slobodno je možete samostalno dodati. Ukoliko je zemlja u vašem vlasništvu, opcijom “Dodaj parcelu” u delu “Zemljišni fond i biljne kulture”, dodajte parcelu izborom jedne od opcija. <strong>Ukoliko želite da dodate zemlju člana</strong>, učinite to dodavanjem parcele u delu “Članovi”, na članu čija je zemlja.",
        HelpAQ3:
          "Ukoliko je <strong>vaša parcela koju želite da dodate upisana u Republičkom geodetskom zavodu (RGZ - Katastar)</strong> opcijom “Zemlja u vlasništvu upisana u katastru” dodajte parcelu i to bez dostavljanja lista nepokrenosti. Izaberite vašu KO i KP i nakon toga će vam biti ponuđene parcele koje možete da dodate.",
        HelpAQ4:
          "<strong>Zemlju u zakupu</strong> možete dodati uz prilaganje (slikanje) ugovora o zakupu <strong>(bez obzira da li je ugovor overen kod notara)</strong>. Ne zaboravite, zemlja koja je u zakupu od države je automatski dodata u Vaš zemljišni fond i ne treba je dodavati ručnim unosom. Ukoliko ne vidite zemlju u zakupu koju ste imali unetu u prethodni Registar, unesite je ponovo.",
        HelpAQ5:
          "Ukoliko vidite <strong>parcele koje ne bi trebalo da budu u vašem RPG</strong> jer ih ne obrađujete u okvoru Vašeg PG, ili iz bilo kog drugog razloga, izbrišite ih iz dela “Zemljišni fond i biljne kulture”.",
        HelpAQ6:
          "<strong>Ukoliko ne vidite HID</strong> obratite se veterinarskoj stanici kako biste zatražili da se na vaš HID upiše broj vašeg PG.",
        HelpAQ7:
          "<strong>Za sve HID-ove</strong> koje vidite i koji se preuzimaju iz baze Uprave za veterinu, potrebno je da unesete podatak o tome <strong>koliko životinja se uzgaja konvencionalnim, a koliko organskim uzgojem.</strong>",

        ////// CONFIRM DIALOG //////
        ConfirmTitleSave: "Sačuvano!",
        ConfirmDescSave: "Uneti podaci su uspešno sačuvani.",
        ConfirmTitleEdit: "Izmenjeno!",
        ConfirmDescEdit: "Podaci su uspešno izmenjeni.",
        ConfirmTitleRequest: "Uspešno!",
        ConfirmDescRequest: "Zahtev je uspešno poslat.",
        ConfirmTitleSuccessGeneralRequest: "Uspešno!",
        ConfirmDescSuccessGeneralRequest:
          "Dokument će biti poslat u vaše eUprava sanduče.",
        ConfirmTitleRefractionPost: "Sačuvano!",
        ConfirmDescRefractionPost: `Vaš zahtev za refakciju je uspešno sačuvan i poslat na obradu.
          Nakon obrade, Rešenje će Vam biti dostavljeno u Vaše eSanduče.`,

        ConfirmDescSaveValidation: `Uneti podaci su uspešno sačuvani.
        Podaci će biti uneti u registar nakon
        provere od strane Registratora.`,
        ConfirmDescEditValidation: `Podaci su uspešno izmenjeni.
        Izmenjeni podaci će biti uneti u registar
        nakon provere od strane Registratora.`,
        ConsentPageDialogTitle: "Zahtev podnet",
        ConsentPageDialogDescription:
          "Uspešno ste završili proces podnošenja zahteva eAgraru za dostavu podataka o poljoprivrednom gazdinstvu ka Agro bazi NIS a.d.",
ConsetnPageDialogConfirmButton:'OK',
        
        ////// LOGIN, REGISTRATION //////
        Login: "Prijavi se",
        LoginTitle: "Prijavite se preko portala za elektronsku identifikaciju",
        LogOut: "Odjavi se",
        ChooseFarm: "Odaberite poljoprivredno gazdinstvo",
        RegisterSearch: "Pretraga registra",
        FarmRegistrationInProgress: "Gazdinstvo u registraciji",

        ////// HEADER //////
        Settings: "Podešavanja",
        "sr-RS-Cyrl": "Ćirilica",
        "sr-RS-Latn": "Latinica",
        Home: "Početna",
        eEncouragement: "ePodsticaji",
        eInbox: "eSanduče",
        Help: "Pomoć",
        Contact: "Kontakt",
        ChangeOrg: "Promeni PG",
        MyAccount: "Moj nalog",
        OrgNumberShort: "BPG",
        ChangeFarm: "Promeni PG",

        ////// DASHBOARD //////
        DashboardInfoTitle: "Obavezna obnova poljoprivrednih gazdinstava",
        DashboardInfoDesc:
          "U toku je obavezna obnova poljoprivrednih gazdinstava. Da bi gazdinstvo ostalo u aktivnom statusu i moglo da konkuriše za podsticaje Ministarstva, neophodno je da izvrši obnovu.",
        AHChangeInfoTitle: "Pregled i promena podataka o gazdinstvu",
        ChangeInfoTitle: "Pregled i promena podataka",
        AHChangeInfoDesc:
          "Svaku promenu u podacima o poljoprivrednom gazdinstvu neophodno je da prijavite Registru u roku od 30 dana od nastupanja promene.",
        AHChangeInfoTooltip:
          "Gazdinstvo je u procesu odobravanja i ne može da se menja",
        AHRenewalTitle: "Obnova poljoprivrednog gazdinstva",
        AHRenewalDesc:
          "Kako biste zadržali AKTIVAN status svog poljoprivrednog gazdinstva neophodno je da proverite podatke o svom gazdinstvu i izvršite obnovu.",
        AHRegisterTitle: "Registracija novog gazdinstva",
        AHRegisterDesc:
          "Registrujte novo gazdinstvo na svoje ime ili na ime kompanije čiji ste zakonski zastupnik.",
        AHRegisterTooltip:
          'Pokrenut je proces registracije novog gazdinstva. Za izmenu podataka, kliknite na "Pregled i promena podataka o gazdinstvu".',
        AHRegistryDataTitle: "Izvod iz Registra Poljoprivrednih Gazdinstava",
        AHRegistryDataDesc:
          "Preuzmite odmah informacije o svim podacima ili podnesite zahtev za dostavu overenog Izvoda iz RPG.",
        AHChangeStatusTitle: "Promena statusa gazdinstva",
        AHChangeStatusDesc:
          "Podnesite zahtev za privremeni prelazak u aktivan status uz dostavljanje neophodne dokumentacije.",
        AHDeleteTitle: "Brisanje poljoprivrednog gazdinstva",
        AHDeleteDesc: "Podnesite zahtev za brisanje gazdinstva iz registra.",
        AHChangePPHolderTitle: "Promena nosioca porodičnog PG",
        AHChangePPHolderDesc:
          "Podnestite zahtev za promenu nosioca Vašeg poljoprivrednog gazdinstva tako što ćete imenovati nekog od članova za novog nosioca i priložiti saglasnost ostalih članova.",
        AHChangePPHolderTooltip:
          "Nije moguće promeniti nosioca dok je proces registracije u toku.",
        AHSetTempHolderTitle: "Postavljanje privremenog nosioca PG",
        AHSetTempHolderDesc:
          "U slučaju smrti trenutnog nosioca, porodičnog PG bilo koji član može preuzeti uklogu privremenog nosioca do okončanja ostavinskog postupka, uz prilaganje saglasnosti ostalih članova.",
        AHChangeLEHolderTitle: "Promena odgovornog lica pravnog subjekta",
        AHChangeLEHolderDesc:
          "Promenite odgovorno lice pravnog subjekta na kog je registrovano poljoprivredno gazdinstvo.",
        AHCaseUpdateTitle: "Dopuna predmeta i dostavljanje podnesaka",
        AHCaseUpdateDesc:
          "Podnesite opšti zahtev ili dostavite dokumenta, dopunu predmeta i sl.",
        AHBasicRequestsTitle: "OPŠTI zahtevi",
        AHBasicRequestsDesc:
          "Podnesite opšti zahtev ili dostavite dokumenta, dopunu predmeta i sl.",
        AHRejectObjectionTitle: "Podnošenje žalbi i odustanak od prigovora",
        AHRejectObjectionDesc:
          "Ubrzajte proces punosnažnosti donetog rešenja izričitim odustankom od prigovora u predviđenom roku.",
        // LAND REPORT
        LandReportTitle: "Prijava obrade zemljišta po drugom osnovu",
        LandReportDesc:
          "Prijavite obradu zemljišta koje niste mogli da upišete u RPG po nekom od četiri zakonska osnova.",
        LandReportWITHOUT_LEASE_AGREEMENT:
          "Prijava obrađivanja zemljišta bez pisanog ugovora o zakupu",
        LandReportCONSOLIDATION:
          "Prijava obrađivanja zemljišta iz komasacije, bez rešenja o raspodeli komasacione mase",
        LandReportUPZ: "Prijava obrađivanja državnog zemljišta bez ugovora",

        BasicInfo: "Osnovni podaci",
        MembersTitle: "Članovi",
        MembersTitleOrdered: "2. Članovi",
        LandTitle: "Zemljišni fond i biljne kulture",
        LandTitleOrdered: "3. Zemljišni fond i biljne kulture",
        AnimalsTitle: "Fond životinja",
        AnimalsTitleOrdered: "4. Fond životinja",
        SaveChanges: "Sačuvaj izmene",
        AHChangeInfoTitleShort: "Pregled I promena podataka o PG",
        AHRenewalTitleShort: "Obnova PG",
        AHRegisterTitleShort: "Registracija novog PG",
        Renew: "Obnova",
        RenewOrdered: "5. Obnova",

        ////// PAGE //////
        PageTitleCONSENT: "ЗАХТЕВ ЗА ДОСТАВЉАЊЕ ПОДАТАКА",
        PageTitleSearch: "PRETRAGA POLJOPRIVREDNIH GAZDINSTAVA",
        PageTitleEDIT: "Pregled i promena podataka o gazdinstvu",
        PageTitleNEW: "Registracija",
        PageTitleRENEW: "Obnova",
        PageTitleSTATEMENTS: "Izvodi i potvrde",
        PageTitleSTATUS_CHANGE: "Promena statusa",
        PageTitleDELETE: "Brisanje iz registra",
        PageTitleCHANGE_HOLDER_PP: "PROMENA NOSIOCA PG",
        PageTitleSET_TEMP_HOLDER: "POSTAVLJANJE PRIVREMENOG NOSIOCA PG",
        PageTitleCHANGE_HOLDER_LE: "PROMENA ODGOVORNOG LICA",
        PageTitleGENERAL_REQUEST: "Podnošenje zahteva",
        PageTitleCASE_UPDATE: "Dopuna predmeta",
        PageTitleCOMPLAINT: "Podnošenje žalbi i odustanak od prigovora",

        ////// NIS //////
        NisRequest: "Захтев за достављање података НИС-у",
        NisRequestInfo:
          "Захтевам да еАграр изврши издавање следећих наведених података: Број пољопривредног газдинства, Име и презиме носиоца пољопривредног газдинства, Тип газдинства: Физичко лице/Правно лице, Општину на којој је регистровано пољопривредно газдинство, Емаил адресу, Укупну површину земљишта под биљним културама на захтевани дан , Укупну површину земљишта под биљним кутлтурама погодну за субвенције на захтевани дан, за потребе коришћења НИС Агро програма лојалности и достави ми наведене податке на адресу НИС-а. Подаци се не могу користити у друге сврхе.",

        ////// RENEWAL //////
        RenewTitle: "Obnova - BPG: {{number}}",
        RenewTitleOrdered: "5. Obnova - BPG: {{number}}",
        RenewInfoTitle: "Izjava o saglasnosti sa unetim podacima",
        RenewInfoDesc:
          "Pod punom krivičnom i materijalnom odgovornošću, kao nosilac porodičnog poljoprivrednog gazdinstva, odnosno kao odgovorno lice poljoprivrednog gazdinstva, izjavljujem:",
        RenewInfoDesc1:
          "da sam pre popunjavanja obrasca pažljivo pročitao i razumeo uputstva za popunjavanje obrazaca zahteva za upis, obnovu registracije i promenu podataka,",
        RenewInfoDesc2:
          "da su svi navedeni i upisani podaci u Registru tačni i istiniti,",
        RenewInfoDesc3:
          "da je sva dokumentacija koju sam priložio verodostojna, kao i da su svi podaci koje sadrži tačni i istiniti,",
        RenewInfoDesc4:
          "da ću omogućiti inspekcijski nadzor na poljoprivrednom gazdinstvu,",
        RenewInfoDesc5:
          "da su prijavljene i upisane sve katastarstke parcele na kojima se obavlja poljoprivredna proizvodnja u skladu sa propisima.",
        RenewData: "Obnovi gazdinstvo",
        AlreadyRenewedTitle:
          "Obnova je već izvršena u ovoj kalendarskoj godini",
        AlreadyRenewedDesc: `Poljoprivredno gazdinstvo je obnovljeno {{date}} 
        Sledeća obnova je potrebna sledeće kalendarske godine.`,

        RenewalConditionsTable: "Pregled ispunjenosti uslova za obnovu",
        CountrysideTourism: "Seoski turizam",
        AtLeastOnePlantCultureOrAnimal: "Poljoprivredna proizvodnja",
        PlantCulture: "Uzgoj biljnih kultura",
        AnimalCulture: "Uzgoj životinja",
        AllowedAprCodes: "Prerada poljoprivrednih proizvoda",
        TechnicalPrerequisiteCondition: "Tehnički preduslovi",
        BasicInfoData: "Popunjeni osnovni podaci",
        AnimalNumberData: "Uneti podaci o broju životinja prema vrsti uzgoja",
        UpzCondition: "Prijavljene kulture na celoj površini iz UPZ",
        RenewalFarmStatus: "Status gazdinstva dozvoljava obnovu",
        HasEDelivery: "Ima aktivno eSanduče",
        HasEmailOrPhoneNumber: "Ima unete kontakt podatke",
        ReadyForRenewal: "Ispunjava uslov za obnovu",

        ////// BASIC INFO //////
        BasicInfoTitleOrdered: "1. Osnovni podaci",
        OrgNumberLong: "Broj poljoprivrednog gazdinstva",
        Status: "Status",
        StatusPG: "Status PG",
        PassiveStatusReason: "Razlog pasivnosti",
        RenewalDate: "Datum obnove",
        ActiveStatusDays: "Vreme trajanje aktivnog statusa",
        RegistrationDate: "Datum registracije",
        LatestChangeDate: "Datum poslednje izmene",
        OrganizationalForm: "Organizacioni oblik",
        MembersCount: "Broj članova PG",
        ArgHolderInfoTitleOrdered: "1.1. Podaci o nosiocu",
        ArgHolderEdit: "Izmeni podatke o nosiocu PG",
        CreateFarm: "Registrujte poljoprivredno gazdinstvo",
        StartFarmCreate: "Započnite proces registracije PG",
        RequestFarmRegistration: "Podnesi zahtev za registraciju",
        ConfirmRegistrationTitle: "Zahtev je uspešno podnet",
        ConfirmRegistrationDesc:
          "Uspešno ste podneli zahtev za registraciju koji je na proveri od strane verifikatora. Uskoro ćete biti obavešteni o statusu zahteva.",
        SelectHolderType: "Izaberite tip poljoprivrednog gazdinstva:",
        HolderTypephysicalPerson: "Nosilac gazdinstva je fizičko lice",
        HolderTypelegalEntity: "Nosilac gazdinstva je pravno lice",
        AskDate: "Datum upita",
        HolderType: "Tip nosioca gazdinstva",
        HolderFirstName: "Ime nosioca",
        HolderLastName: "Prezime nosioca",
        HolderTitle: "Naziv nosioca",
        HolderMunicipality: "Opština sedišta PG",
        ConsentRequestDate: "Datum za koji se šalju podaci",
        UsedSurfaceConsent:
          "Ukupna površina pod kulturama na dostavljeni datum",
        TotalCultureConvinientSurface:
          "Ukupna površina pod kulturama koja ispunjava uslov za subvencije na dostavljeni datum",
        RequestForDataSubmission: "Zahtev za dostavljanje podataka",
        RequestForDataSubmissionNIS: "Zahtev za dostavljanje podataka NIS",
        Request: "Zahtev",
        RequestSubmittionInfo: `Zahtevam da eAgrar izvrši izdavanje sledećih navedenih podataka: Broj poljoprivrednog gazdinstva, Ime i prezime nosioca poljoprivrednog gazdinstva, Tip gazdinstva: Fizičko lice/Pravno lice, Opštinu na kojoj je registrovano
poljoprivredno gazdinstvo, Email adresu, Ukupnu površinu zemljišta pod biljnim kulturama na zahtevani dan , Ukupnu površinu zemljišta pod biljnim kutlturama pogodnu za subvencije na zahtevani dan, za potrebe korišćenja {{provider}} Agro programa lojalnosti i dostavi mi navedene podatke na adresu {{provider}}-a. Podaci se ne mogu koristiti u druge svrhe`,

        ////// STATEMENT AND CERTIFICATES //////
        StatementsAndCertificatesTitle: "Izvodi i potvrde",
        StatementSectionTitle:
          "Izvod podataka iz Registra poljoprivednih gazdinstava",
        RequestStatementTitle:
          "Zatražite izvod podataka ili potvrdu o statusu.",
        RequestStatementDesc:
          "Ovde možete preuzeti informativni izvod o svim podacima trenutno upisanim u RPG za Vaše poljoprivredno gazdinstvo ili podneti zahtev da Vam se dostavi zvaničan overeni izvod. Takođe, možete podneti zahtev za dostavljanje overene potvrde o statusu poljoprivrednog gazdinstva.",
        SubmitRequest: "Podnesi zahtev",
        CancelRequest: "Odustani od zahteva",
        ConsentPageNoAccess:
          "Niste ovlašćeni za preuzimanje podataka o poljoprivrednom gazdinstvu broj",
        CurrentStatusCertificateTitle:
          "Potvrda o trenutnom statusu poljoprivrednog gazdinstva",
        DurationOdTheActiveStatusCertificateTitle:
          "Potvrda o trajanju aktivnog statusa poljoprivrednog gazdinstva za",
        StatementType: "Vrsta izvoda",
        DeliveryType: "Način dostave",
        StatementPurpose: "Svrha izvoda",
        DeliveryTypeINBOX: "Dostava overenog izvoda",
        DeliveryTypeDOWNLOAD: "Preuzimanje neoverenog izvoda",
        CertificatePurpose: "Svrha potvrde",
        AtDate: "Na dan",
        AtDateHelper:
          "Mogući datumi su 01.09.2023. ili datumi posle 27.02.2024.",

        ////// PESTICIDE REFACTIONS //////
        PesticideProcurements: "Evidencija kupovine pesticida",
        PlantProtectionProduct: "Sredstvo za zaštitu bilja",
        PlantProtectionProductContraint:
          "Morate uneti datum kupovine proizvoda pre izbora sredstva za zaštitu bilja",
        Pesticides: "Pesticidi",
        AddPesticidePurchase: "Dodaj kupovinu pesticida",
        PesticideDetails: "Detalji kupovine pesticida",
        PesticideProcurementsDesc:
          "Podnesite novi zahtev za evidenciju kupovine pesticida",
        Category: "Kategorija",
        Producer: "Proizvođač",
        Quantity: "Količina",
        UnitOfMeasure: "Jedinica mere",
        PurchaseDate: "Datum kupovine",
        DeletePesticideDesc:
          "Da li ste sigurni da želite da uklonite izabranu kupovinu?",

        ////// STATUS CHANGE //////
        RequestStatusChangeTitle:
          "Podnesite zahtev za prevremeni prelazak u aktivan status",
        RequestStatusChangeDesc:
          "Ovde možete podneti zahtev za prevremeni prelazak u aktivni status gazdinstva koje je u pasivnom statusu uz prilaganje dokaza za osnov prelaska u aktivno stanje.",
        StatusChangeTitle: "Zahtev za promenu statusa",
        StatusChangeToActiveRequest: "Zahtev za prelazak u aktivno stanje",
        RequestDescription: "Opis zahteva",
        UploadedFiles: "Priložena dokumenta",
        RequestComplementStatusChange: "Podnesi zahtev za dopunu registracije",
        PermanentHolder: "Promena u stalnog nosioca",
        PermanentHolderDesc: `Da biste postali stalni nosilac, potrebno je da:

        • Bude završen ostavinski postupak i sprovedena promena u Katastru nepokretnosti,
        • Ukoliko potvrdite da želite da budete stalni nosilac, sve parcele koje se vode na prethodnog nosioca
        kao i parcele koje je on prethodno kao član ustupio PG će biti uklonjene iz PG i morate ih ponovo uneti. 
        • Dugme Da za saglasnost, dugme Ne za odustajanje`,

        ////// INVOICES //////
        Invoices: "Računi",
        ReplaceInvoice: "Dopuni podatke o računu",
        DeleteInvoice: "Izbriši račun",
        DeleteInvoiceDesc: "Da li ste sigurni da želite da izbrišete račun?",
        FuelInvoices: "Računi za gorivo",
        InvoicesDesc:
          "Ovde možete pregledati sve izdate fiskalne račune za gorivo kupljeno za potrebe Vašeg gazdinstva ili dodati fakture i uvozne fakture za gorivo kupljeno u veleprodaji ili uvezeno.",
        InvoiceNumber: "Broj računa",
        InvoiceDate: "Datum računa",
        SellerTaxId: "PIB prodavca",
        InvoiceAdd: "Dodaj fakturu",
        NewInvoice: "Dodavanje novog računa",
        NewInvoiceDesc:
          "Unesite podatke sa računa, dodajte stavke i nakon toga priložite potrebnu dokumentaciju",
        AddInvoiceItems: "Priložite stavke računa",
        FuelType: "Tip goriva",
        Liter: "Količina u litrima",
        LiterHelpText: "Količina u litrima mora biti manja od {{value}} l",
        InvoiceItems: "Stavke računa",
        InvoiceUsed: "Iskorišćen",
        InvoiceItemAdd: "Dodaj stavku",
        InvoiceItemsDesc:
          "Priložite stavke računa i nakon toga priložite potrebnu dokumentaciju",
        InvoiceEvidenceTitle: "Priložite dokumenta",
        InvoiceEvidenceDesc:
          "Priložite originalnu fakturu sa SEF-a ili fotografiju/sken veleprodajnog ili uvoznog računa, kao i dokaz o izvršenom plaćanju.",
        InvoiceNotApprovedInfo: "Račun je u procesu provere.",
        TotalInvoiceFuels: "Ukupno iskorišćeno goriva / ukupno na fakturama",
        InvoicesPageInfo: `Ova stranica namenjena je evidenciji fiskalnih računa i faktura za gorivo i preko nje se ne vrši podnošenje Zahteva za refakciju.
        
        Na ovoj stranici možete videti listu svih fiskalnih računa automatski preuzetih iz sistema eFiskalizacije koji na sebi imaju obavezne informacije o Poljoprivrednom Gazdinstvu.
        
        Takođe, na ovoj stranici možete dodati račune/fakture za gorivo kupljeno u veleprodaji kao i uvozne fakture za gorivo kupljeno iz uvoza.`,
        InvoicesPageInfoBox: `Ovde možete proveriti svoje fiskalne račune ili dodati novu veleprodajnu ili uvoznu fakturu. Fakture koje dodate, moraju biti verifikovane od strane sistema kako biste mogli da ih iskoristite za podnošenje zahteva za refakciju. Dodavanje računa ne podrazumeva automatsko podnošenje Zahteva za refakciju - da biste podneli Zahtev posetite <link1>ovu stranicu</link1>`,
        InoviceType: "Tip fakture",
        WholesaleInvoice: "Veleprodajna faktura",
        ImportInvice: "Uvozna faktura",
        FiscalInvoice: "Fiskalni račun",
        NoFiscalInvoiceInfo:
          "Fiskalni račun nije moguće dodati ručno, svi fisklani računi koji na sebi imaju obavezne informacije o Poljoprivrednom Gazdinstvu se automatski preuzimaju iz sistema eFiskalizacije.",
        InvoiceDisclaimer: `Upoznat/a sam da:
        - faktura koju ovom prilikom dodajem MORA BITI naknadno sistemski proverena da bi se mogla koristi za podnošenje zahteva za ostvarivanje prava na refakciju plaćene akcize u okviru softverskog rešenja eAgrar, 
        - dodavanje fakture NE ZNAČI i istovremeno podnošenje zahteva za ostvarivanje prava na refakciju plaćene akcize u okviru softverskog rešenja eAgrar.`,

        ////// REFACTION //////
        Refaction: "Refakcija",
        RefactionTitle: "Zahtev za refakciju akcize",
        RefactionDesc:
          "Podnesite novi zahtev za refakciju plaćene akcize na gorivo ili pogledajte sve svoje prethodno podnete zahteve.",
        RefactionPageInfoBox:
          "Ovde možete podneti novi Zahtev za refakciju na osnovu postojećih računa ili pregledati prethodno podnete zahteve. Ukoliko ste gorivo kupili preko veleporodajne fakture ili uvezli, potrebno je prvo da dodate tu fakturu na <link1>ovoj stranici</link1>",
        RefactionNewRequestAdd: "Podnesi novi zahtev za refakciju",
        RefactionRequestAdd: "Sačuvaj zahtev",
        RefactionRequestSubmit: "Podnesi zahtev",
        NewRefactionRequest: "Podnošenje novog zahteva za refakciju",
        NewRefactionRequestDesc:
          "Izaberite račune za koje želite da podnesete zahtev za refakciju, a zatim definišite parcele i biljne kulture na koje je utrošeno gorivo sa odabranih računa",
        RefactionRequestDelete: "Brisanje nacrta zahteva",
        RefactionRequestDeleteDesc:
          "Da li ste sigurni da želite da obrišete nacrt zahteva?",
        NoInvoicesForRefaction:
          "Nemate ni jedan račun za koji može da se podnese zahtev za refakciju",
        AddRefactionRequestItems: "Odaberite parcele i kulture",
        RequestItems: "Stavke zahteva",
        RefactionRequestItemsTitle: "Parcele i biljne kulture",
        RefactionRequestItemsDesc:
          "Rasporedite gorivo sa izabranih računa na zemljišni fond i biljne kulture na kojima je potrošeno",
        DeleteItem: "Ukloni parcelu",
        MaxLiterForCulture:
          "Maksimalna količina goriva za ovu biljnu kulturu je {{value}} l",
        RemainLiters:
          "Preostala količina goriva za ovu parcelu i odabranu biljnu kulturu je {{value}} l",
        ConfirmRefactionRequest:
          "Trenutno podnosite {{value}} zahtev(a) za refakciju",
        RefactionStatusDRAFT: "U pripremi",
        RefactionStatusSUBMITTED: "Primljen",
        RefactionStatusAPROVED: "Odobren",
        RefactionStatusREJECTED: "Odbijen",
        RefactionStatusSENT: "Poslat",
        RefractionStatusSuccessfullySubmitted:
          "Vaš zahtev je zaprimljen pod brojem: {{value}}",
        RefractionSuccessfullyDrafted:
          "Vaš zahtev se nalazi u pripremi. Potvrdite zahtev.",
        UsedFuelPreview: "Pregled iskorišćenog goriva",
        InvoiceLiterSum:
          "Ukupno raspoloživih litara na osnovu izabranih računa",
        LiterSum:
          "Ukupno iskorišćenih litara goriva na osnovu stavki zahteva za refakciju",
        SummaryPreview:
          "Odabrani računi neće moći da se koriste u budućim zahtevima za refakciju goriva bez obzira na to da li imaju preostale neiskorišćene količine goriva",
        SubmitRefactionRequest: "Podnošenje zahteva za refakciju",
        SubmitRefactionRequestDesc:
          "Da li ste sigurni da potvrđujete podnošenje zahteva?",

        ////// DELETE //////
        RequestForFarmDelete: "Zahtev za brisanje PG",
        DeleteTitle: "Brisanje poljoprivrednog gazdinstva iz Registra",
        RequestDeleteTitle:
          "Podnesite zahtev za brisanje poljoprivrednog gazdinstva iz RPG",
        RequestDeleteDesc:
          "Ovde možete podneti zahtev za brisanje gazdinstva iz Registra poljoprivrednih gazdinstava uz obrazloženje i eventualni dokaz za osnov Vašeg zahteva.",
        DeleteRequestFromRegister: "Zahtev za brisanje iz registra",

        ////// CHANGE HOLDER //////
        ChangeHolderTitle: "Promena nosioca PG",
        RequestChangeHolderTitle: "Promenite nosioca porodičnog PG",
        RequestChangeHolderDesc:
          "Izvršite promenu nosioca porodičnog poljoprivrednog gazdinstva čiji ste trenutno nosilac i postavite jednog od trenutnih članova PG kao novog nosioca. Promenom nosioca, svo zemljište prijavljeno na PG u trenutku promene na koje ste kao nosilac imali pravo korišćenja ostaće i dalje u okviru gazdinstva.",
        NewHolderStatement: "Izjavu novog nosioca da prihvata da bude nosilac",
        MembersStatementForChangeHolder:
          "Izjavu svih članova da su članovi domaćinstva novog nosioca",
        BankAccountRegisteredConfirmation:
          "Potvrdu iz banke ili ugovor o otvaranju namenskog računa.",
        DownloadHolderStatement: "Preuzmite formular za izjavu nosioca",
        ChangeHolderStatement:
          "Nakon prihvatanja Vašeg zahteva, trenutni namenski račun će biti izbrisan iz PRG i potrebno je da novi nosilac otvori novi namenski račun i isti upiše u Registar PG.",
        NewFarmHolder: "Novi nosilac PG",

        ////// CHANGE LEGAL REPRESENTATIVE //////
        ChangeLRTitle: "Promena odgovornog lica pravnog subjekta",
        RequestChangeLRTitle:
          "Promenite odgovorno lice za PG registrovano na privredni subjekt",
        RequestChangeLRDesc:
          "Podnesite zahtev za imenovanje Vas kao odgovornog lica za poljoprivredno gazdinstvo koje je registrovano na pravno lice čiji ste zatupnik.",
        NewLREvidence:
          "Dokaz osnova po kom zahtevate da budete upisani kao odgovorno lice navedenog poljoprivrednog gazdinstva.",
        NewLRFirstName: "Ime novog odgovornog lica",
        NewLRLastName: "Prezime novog odgovornog lica",
        NewLRtPIN: "JMBG novog odgovornog lica",

        ////// SET TEMP HOLDER //////
        TempHolderTitle: "Privremeni nosilac PG",
        RequestTempHolderTitle: "Imenovanje privremenog nosioca",
        RequestTempHolderDesc:
          "Podnesite zahtev za imenovanje Vas kao privremenog nosioca u slučaju smrti trenutnog nosiova porodičnog poljoprivrednog gazdinstva čiji ste član, a do okončanja ostavinskog postupka i sprovođenja promena u katastru nepokretnosti.",
        HolderDeathCertificate:
          "Izvod iz Matične knjige umrlih trenutnog nosioca",
        MembersStatementForTempHolder:
          "Izjavu svih članova domaćinstva sa imenovanjem privremenog nosioca",
        DownloadMembersStatement: "Preuzmite formular za izjavu članova",
        ConfirmationAccountNumber: "Potvrdu / Ugovor za namenski tekući račun",
        TempHolderStatement:
          "Izjavljujem da prihvatam da budem upisan u Registar poljoprivrednih gazdinstava, kao privremeni nosilac porodičnog poljoprivrednog gazdinstva do okončanja ostavinskog postupka i sprovođenja promene u katastru nepokretnosti.",
        TempHolderFirstName: "Ime privremenog nosioca",
        TempHolderLastName: "Prezime privremenog nosioca",
        TempHoldertPIN: "JMBG privremenog nosioca",

        ////// BASIC REQUEST //////
        RequestTitle: "Podnošenje zahteva",
        GeneralRequestTitle:
          "Podnesite zahtev Registru poljoprivrednih gazdinstava ",
        GeneralRequestDesc:
          "Ovde možete podneti zahtev u slobodnoj formi i dostaviti željene priloge ili  dokumenta uz Vaš zahtev.",
        GeneralRequests: "Opšti zahtevi",

        ////// CASE UPDATE //////
        CaseUpdate: "Dopuna predmeta",
        CaseUpdateRequestTitle: "Dopunite predmet ili dostavite novi podnesak ",
        CaseUpdateRequestDesc:
          "Ovde možete dopuniti zahtev ili neki drugi predmet koji je trenutno u obradi ili dostaviti neku drugu vrstu podneska. Potrebno je da prvo izaberete broj predmeta na koji se podnesak ili dopuna odnosi, a zatim priložite dokumenta i dodate napomenu ukoliko je potrebno.",
        CaseNumber: "Broj predmeta",

        ////// COMPLAINT //////
        ComplaintTitle: "Žalba / odustanak od prigovora",
        ComplaintPageTitle:
          "Podnošenje žalbe ili odustanak od prigovora na doneto rešenje",
        ComplaintRequestTitle:
          "Podnesite žalbu ili odustanite od prigovora na doneto rešenje ",
        ComplaintRequestDesc:
          "Ovde možete podneti žalbu na doneto rešenje ili ubrzati proces punosnažnosti donetog rešenja izričitim odustankom od prigovora u predviđenom roku. Potrebno je da prvo izaberete broj predmeta po kom je doneto rešenje na koji se žalba ili odustanak odnosi, a zatim priložite dokumenta i dodate napomenu ukoliko je potrebno.",
        ComplaintType: "Vrsta podneska",
        RequestTypeCOMPLAINT: "Žalba",
        RequestTypeCOMPLAINT_WAIVER: "Odustanak od prigovora",

        ////// HOLDER //////
        PersonalIdentityNumber: "JMBG",
        FirstName: "Ime",
        LastName: "Prezime",
        BirthDate: "Datum rođenja",
        BirthPlace: "Mesto rođenja",
        BirthCountry: "Država rođenja",
        Gender: "Pol",
        WorkActivity: "Radna aktivnost",
        Education: "Školovanje",
        HolderNotVerifiedInfo: "Nosilac gazdinstva nije verifikovan",
        DisplayPersonalIdentityNumber: "Prikaži JMBG",
        Temporary: "Privremeni",
        Permanently: "Stalni",

        ////// LEGAL ENTITY //////
        LegalEntityInfoTitleOrdered: "1.1. Podaci o Pravnom licu",
        LegalEntitySize: "Veličina pravnog lica",
        RegistrationNumber: "Matični broj",
        TaxId: "PIB",
        Jbkjs: "JBKJS",
        Name: "Naziv",
        NumberOfEmployees: "Број стално запослених",
        DedicatedRegisterNumber: "Broj iz lokalnog registra",
        LegalEntityNotVerifiedInfo: "Pravno lice je u procesu provere",
        AgriculturalProduction: "Poljoprivredna proizvodnja",
        AgriculturalProductsProcessing: "Prerada poljoprivrednih proizvoda",
        NonAgriculturalProduction:
          "Druge nepoljoprivredne delatnosti (ruralni turizam, stari zanati i sl.)",

        LEFormLLC: "Društvo sa ograničenom odgovornošću",
        LEFormENTREPRENEUR: "Preduzetnik",
        LEFormOTHER: "Ostalo",
        MICRO: "Mikro",
        SMALL: "Malo",
        MEDIUM: "Srednje",
        LARGE: "Veliko",

        ////// HEAD OFFICE //////
        HeadOfficeTitleOrdered: "1.2. Sedište",
        HeadOfficeEdit: "Izmeni podatke o sedištu PG",
        Street: "Ulica",
        NumberLetter: "Broj i slovo",
        Place: "Mesto",
        Municipality: "Opština / grad",
        PostalCode: "Poštanski broj",
        HeadOfficeNotVerifiedInfo: `Promenjena adresa sedišta je u procesu provere od strane Registratora.
        Priložene dokaze možete pogledati i po potrebi dopuniti, klikom na dugme "Priloži dokumente" u donjem delu sekcije.`,

        ////// CONTACT //////
        ContactTitleOrdered: "1.3. Kontakt podaci",
        ContactEdit: "Izmeni kontakt podatke",
        Email: "E-pošta",
        EmailAddress: "Adresa е-poštе",
        EmailHelper: "Adresa na koju će stizati obaveštenja",
        EmailNotVerified: "Adresa nije verifikovana.",
        EmailNotVerifiedInfo: `Adresa e-pošte nije verifikovana.
        Kliknite na dugme "Izmeni kontakt podatke", pa zatim na dugme "Potvrdi adresu e-Pošte" i pratite dalja uputstva.`,
        VerifyEmail: "Potvrdi adresu",
        PhoneNumber: "Broj mobilnog telefona",
        PhoneNumberHelper: "Na ovaj broj će stizati SMS obaveštenja",
        PhoneNumberNotVerified: "Broj mobilnog telefona nije verifikovan",
        PhoneNumberNotVerifiedInfo: `Broj mobilnog telefona nije verifikovan.
        Kliknite na dugme "Izmeni kontakt podatke", pa zatim na dugme "Potvrdi broj telefona" i pratite dalja uputstva.`,
        VerifyPhoneNumber: "Potvrdi broj",
        EmailConfirmTitle: "Potvrda adrese e-pošte",
        EmailConfirmDesc:
          "Unesite KOD koji Vam je poslat na adresu e-pošte koju ste uneli",
        PhoneConfirmTitle: "Potvrda broja telefona",
        PhoneConfirmDesc:
          "Unesite KOD koji Vam je poslat na broj mobilnog telefona koju ste uneli",
        ResendActivationCode: "Pošaljite mi ponovo aktivacioni kod",
        PreferedDeliveryType: "Način obaveštavanja",
        ContactDeliveryTypeINBOX: "eSanduče",
        ContactDeliveryTypeEMAIL: "Email",
        ContactDeliveryTypeSMS: "SMS",

        ////// BANK ACCOUNT //////
        BankAccountTitleOrdered: "1.4. Namenski račun",
        BankAccountEdit: "Izmeni podatke o računu",
        AccountNumber: "Broj računa",
        AccountNumberHelper: "Namenski račun za uplate iz budžeta",
        AccountNumberNotVerified: "Vlasništvo nad računom nije potvrđeno",
        AccountNumberNotVerifiedInfo: `Vlasništvo nad namenskim računom je u procesu provere.
        Priložene dokaze možete pogledati klikom na dugme "Priloži dokumente" neposredno ispod polja za unos broja računa.`,
        BankName: "Naziv banke",
        ReferenceNumber: "Poziv na broj",

        ////// LEGAL REPRESENTATIVE //////
        LegalRepTitleOrdered: "1.5. Podaci o odgovornom licu",
        LegalRepEdit: "Izmeni podatke o odgovornom licu",
        LegalRepresentativeNotVerifiedInfo:
          "Podaci o odgovornom licu su u procesu provere i biće upisani u Registar nakon provere Registratora.",

        ////// AUTHORIZED PERSON //////
        AuthorizedPersonsTitle: "Ovlašćeni zastupnici",
        AuthorizedPersonsTitleOrdered: "2. Ovlašćeni zastupnici",
        AuthorizedPersonEdit: "Izmeni podatke o zastupniku PG",
        AuthorizedPersonAdd: "Dodaj zastupnika",
        NewAuthorizedPerson: "Dodavanje novog zastupnika",
        RemoveAuthorizedPerson: "Brisanje zastupnika",
        RemoveAuthorizedPersonDesc:
          "Da li ste sigurni da želite da izbrišete zastupnika",

        ////// FILES //////
        Document: "Dokument",
        DocumentsNeeded: "Potrebna dokumenta",
        DocumentsNeededDesc: "Potrebno je da priložite sledeća dokumenta:",
        UploadAttachments: "Priloži dokumente",
        RemoveDocument: "Ukloni prilog",
        RemoveDocumentDesc: "Da li ste sigurni da želite da uklonite prilog",
        PCUpload: "Dodaj sa računara",
        MobileUpload: "Slikaj mobilnim telefonom",
        UploadDocument: "Dodaj prilog",
        justDragAndDropFile: "ili samo prevuci dokument sa računara ovde",

        EvidenceTitleHEAD_OFFICE: "Potvrda adrese sedišta",
        EvidenceDescHEAD_OFFICE: `Da biste potvrdili adresu sedišta gazdinstva, neophodno je da priložite očitanu ličnu kartu nosioca, ukoliko je sa čipom, ili za ličnu kartu bez čipa priložite fotografije ili skenirane obe strane.
        Dokument možete priložiti sa računara ili slikajte kamerom svog mobilnog telefona.`,
        EvidenceTitleLEGAL_REPRESENTATIVE: "Potvrda odgovornog lica",
        EvidenceDescLEGAL_REPRESENTATIVE: `Priložite dokaz o ovlašćenjima lica koje se prijavljuje kao odgovorno lice poljoprivrednog gazdinstva i očitanu ličnu kartu odgovornog lica ukoliko je sa čipom, ili za ličnu kartu bez čipa priložite fotografije ili skenirane obe strane.
        Dokument možete priložiti sa računara ili slikajte kamerom svog mobilnog telefona.`,
        EvidenceTitleBANK_ACCOUNT: "Potvrda vlasništva nad računom",
        EvidenceDescBANK_ACCOUNT: `Dodajte potvrdu iz banke ili ugovor o otvaranju računa.
        Priložite sa računara ili slikajte kamerom svog mobilnog telefona`,

        ScanQRInfo: `Skenirajte prikazani QR kod na svom mobilnom uređaju i nakon otvaranja
        aplikacije slikajte dokument koji želite da priložite.`,
        QRExpired: "QR kod je istekao!",
        FileUploadSuccess: "Datoteka uspešno priložena!",
        AddNewFile: "Priloži novu",
        StartCamera: "Pokreni kameru",
        UploadFilePhone: "Unesite datoteku sa telefona",
        MobileSesionOpened: "Nastavite dodavanje na svom mobilnom uredjaju.",
        SessionExpiresAt: "Sesija ističe",
        FilesCount: "Broj dodatih dokumenata",

        ////// MEMBERS //////
        Members: "Članovi",
        MemberAdd: "Dodaj člana",
        ReplaceMember: "Dopuni podatke o članu",
        MemberEdit: "Izmeni podatke o članu",
        MemberEditDesc:
          "Izmenite podatke o članu i nakon toga priložite izjavu",
        NewMember: "Dodavanje novog člana",
        NewMemberDesc: "Unesite podatke o članu i nakon toga priložite izjavu",
        MemberNotVerifiedInfo: `Status prijavljenog člana je u procesu provere.
          Nakon potvrde od strane Registra moći ćete da priložite i zemljište člana.`,
        Inactive: "Neaktivan",
        SaveMemberData: "Sačuvaj podatke o članu",
        DeleteMember: "Izbriši člana",
        DeleteMemberDesc: "Da li ste sigurni da želite da izbrišete člana?",
        AddMemberStatement: "Priloži izjavu člana",
        AddMemberLandLeaseStatement: "Izjava člana o ustupanju zemljišta",
        MemberLandNotVerifiedInfo: `Pravo korišćenja nad parcelom je u procesu provere i biće dodato u Registar nakon provere Regstratora.
        Priložene dokaze možete pogledati klikom na dugme ”Prilozi” u dnu sekcije.
        Biljne kulture prijavljene na ovoj parceli neće biti upisane u Registar niti uzete u obzir sve dok pravo korišćenja ne bude provereno i potvrđeno.`,
        AddStatementTitleMEMBER_STATEMENT:
          "Izjava člana domaćinstva da jeste član domaćinstva",
        AddStatementDescMEMBER_STATEMENT: `Da bi član domaćinstva bio upisan u registar, potrebno je da lično da izjavu da je član porodičnog poljoprivrednog domaćinstva.
        Izjava se može potpisati elektronski ili u papirnoj formi i priložiti skenirana ili fotografisana.`,
        AddStatementTitleMEMBER_LAND_STATEMENT:
          "Izjava člana domaćinstva o ustupanju zemljišta",
        AddStatementDescMEMBER_LAND_STATEMENT: `Da bi član domaćinstva ustupio PG lično zemljište u svojini ili zakupu, potrebno je da lično da izjavu o tome.
        Izjava se može potpisati elektronski ili u papirnoj formi i priložiti skenirana ili fotografisana.`,

        ////// LAND //////
        Land: "Parcela",
        AddMembersLandTitle:
          "Prijava zakupljenog ili ustupljenog zemljišta člana",
        AddMembersLandDesc: `U listi su prikazane sve katastarske parcele koje član ustupa gazdinstvu.
        Nakon dodavanja svih parcela, potrebno je priložiti izjavu člana o ustupanju zemljišta.`,
        AddMemberLandTitle: "Prijava zemljišta člana",
        AddMemberLandTitleName: `Prijava zemljišta člana
        {{name}}`,
        AddMemberLandDesc:
          "Odaberite člana čije zemljište prijavljujete na gazdinstvu.",
        AddNewLand: "Dodaj još parcela na listu",
        AddLandToContinue: `Dodajte zemljište klikom na "Dodaj još parcela na listu"`,
        LandMunicipality: "Kat.opština/Opština",
        RemoveLand: "Ukloni zemljište",
        RemoveLandDesc:
          "Da li ste sigurni da želite da uklonite zemljište člana sa liste",
        RequiredFieldsMissingLandCultures:
          "Nisu uneti podaci za sva obavezna polja biljne kulture na parceli.",
        ReplaceLand: "Dopuni podatke o parceli",
        ReplaceLandTitle: "Izmena i dopuna podataka",
        ReplaceLandDesc: "Izaberite vlasnika parcele {{name}}",
        HolderLand: "Zemljište nosioca gazdinstva",
        MemberLand: "Zemljište člana",
        SelectMember: "Izaberite člana",

        MembersLandTitle:
          "Zemljište koje je član ustupio poljoprivrednom gazdinstvu",
        RequiredFieldsMissingMembersLands:
          "Nisu uneti podaci za sva obavezna polja zemljišta koje je član ustupio poljoprivrednom gazdinstvu.",
        UnreportedLandTitle: "Neprijavljeno zemljište u državnoj svojini!",
        UnreportedLandDesc:
          "Postoji {{ha}}ha {{a}}a {{m}}m2 pod zakupom od države za koje niste prijavili biljnu kulturu. Svo zemljište u državnoj svojini mora biti prijavljeno u registru.",
        LandEdit: "Izmeni podatke o parceli",
        LandAdd: "Dodaj parcelu",
        LandAddNew: "Dodaj novu parcelu",
        SaveLand: "Sačuvaj parcelu",
        HolderLandAdd: "Dodaj parcelu nosioca",
        MemberLandAdd: "Dodaj parcelu člana",
        LandsAddAll: "Priloži sve parcele",
        LandDelete: "Ukloni celu parcelu",
        DeleteLand: "Ukloni parcelu",
        DeleteLandDesc:
          "Obratite pažnju da li nakon brisanja ispunjavate uslove za aktivan status. Da li ste sigurni da želite da uklonite parcelu?",
        CadastreNumberShort: "Kat.par.",
        CadastreNumber: "Broj kat. parcele",
        CadastreMunicipality: "Katastarska opština",
        LandName: "Naziv parcele",
        CadastreCulture: "Katastarska kultura",
        Surface: "Površina parcele",
        UsageBasis: "Osnov korišćenja",
        UsageBasisUPZ: "Osnov korišćenja UPZ",
        LeaseType: "Tip zakupa/ustupanja",
        CadastreCultureRecapitulationTitle:
          "Pregled prijavljene biljne kulture",
        SurfaceTitleFormatted: "Površina: ha a m2",
        TotalSurfaceUnderCultures: "Ukupno pod kulturama",
        NoReportedCulture: "Bez prijavljene kulture",
        TotalSurfaces: "Ukupno zemljište koje se obrađuje",
        LeaseEndDate: "Trajanje zakupa do",
        LessorPersonalIdentityNumber: "JMBG zakupodavca",
        LessorRegistrationNumber: "Matični broj zakupodavca",
        LessorIdentificationNumber: "JMBG / MB zakupodavca",
        UsedSurface: "Površina koja se obrađuje",
        CADASTRE_OWNERSHIP: "Vlasništvo",
        OWNERSHIP_OUT_CADASTRE: "Vlasništvo bez RGZ",
        LEASE: "Zakup/Ustupanje",
        LEASE_CONSOLIDATION: "Zakupljeno zemljište iz komasacije",
        CONSOLIDATION: "Komasacija",
        // Add land
        AddLandTitle: "Prijava zemljišta nosioca",
        AddLandDesc:
          "Odaberite osnov korišćenja zemljišta koje će biti registrovano u okviru PG.",
        AddExitingLandConfirm: "Želim da ponovo unesem istu parcelu",
        AddExistingLandDesc:
          "Na vašem gazdinstvu već postoji upisana parcela sa istim podacima, da li ste sigurni da želite ponovo da je dodate?",
        CadastreLandIsMissingDesc:
          "Ukoliko u listi ne vidite katastarsku parcelu koju posedujete, vratite se nazad a zatim dodajte ovu parcelu putem opcije ”Zemljište u svojini nosioca koje nije uređeno u Katastru i priložite izvod iz katastra kojim se ovo potvrđuje.",

        AddLandTitleCADASTRE_OWNERSHIP:
          "Zemljište u svojini upisano u Katastar",
        AddMemberLandTitleCADASTRE_OWNERSHIP: `Zemljište u svojini upisano u Katastar
        {{name}}`,
        AddLandDescCADASTRE_OWNERSHIP:
          "Odaberite katastarku opštinu i broj katastarske parcele iz padajuće liste i upišite površinu koja se obrađuje a zatim dodajte parcelu na poljoprivredno gazdinstvo.",
        AddLandTitleOWNERSHIP_OUT_CADASTRE:
          "Zemljište u svojini koje nije uređeno u Katastru",
        AddMemberLandTitleOWNERSHIP_OUT_CADASTRE: `Zemljište u svojini koje nije uređeno u Katastru
        {{name}}`,
        AddLandDescOWNERSHIP_OUT_CADASTRE:
          "Unesite katastarku opštinu, broj katastarske parcele i unesite ukupnu površinu parcele i površinu udela.",
        AddLandTitleLEASE: "Zakupljeno ili ustupljeno zemljište",
        AddMemberLandTitleLEASE: `Zakupljeno ili ustupljeno zemljište
        {{name}}`,
        AddLandDescLEASE:
          "Unesite JMBG zakupodavca/vlasnika zemljišta, katastarku opštinu, broj katastarske parcele i unesite ukupnu površinu parcele i površinu dela koji se obrađuje. Takođe, potrebno je uneti i tip zakupa kao i period do kad je ugovoren zakup/ustupanje.",
        AddLandTitleLEASE_LEGAL_ENTITY: "Zakupljeno ili ustupljeno zemljište",
        AddMemberLandTitleLEASE_LEGAL_ENTITY: `Zakupljeno ili ustupljeno zemljište
        {{name}}`,
        AddLandDescLEASE_LEGAL_ENTITY:
          "Unesite Matični broj zakupodavca/vlasnika zemljišta, katastarku opštinu, broj katastarske parcele i unesite ukupnu površinu parcele i površinu dela koji se obrađuje. Takođe, potrebno je uneti i tip zakupa kao i period do kad je ugovoren zakup/ustupanje.",
        AddLandTitleCONSOLIDATION: "Zemljište iz komasacije",
        AddMemberLandTitleCONSOLIDATION: `Zemljište iz komasacije
        {{name}}`,
        AddLandDescCONSOLIDATION:
          "Unesite broj rešenja/izvoda o raspodeli komasacione mase, katastarku opštinu, broj katastarske parcele dodeljen rešenjem o komasaciji, odaberite katastarsku kulturu (ukoliko znate) i unesite ukupnu površinu parcele i površinu dela koji poljoprivredno gazdinstvo obrađuje.",
        AddLandTitleLEASE_CONSOLIDATION: `Zakupljeno zemljište iz komasacije`,
        AddLandDescLEASE_CONSOLIDATION:
          "Unesite JMBG ili Matični broj zakupodavca/vlasnika zemljišta, katastarku opštinu, broj katastarske parcele i unesite ukupnu površinu parcele i površinu dela koji se obrađuje. Takođe, potrebno je uneti i tip zakupa kao i period do kad je ugovoren zakup/ustupanje.",
        AfterThatSubmitEvidence: "Nakon toga priložite dokumente:",
        LeaseContract: "Ugovor o zakupu ili ustupanju",
        CadastreDocument: "Izvod iz Katastra ili Zemljišne knjige",
        Attachment2a:
          "Prilog 2a - Izjava odgovornog lica u pravnom licu koje je vlasnik zemljišta i zakupodavac, odnosno ustupilac zemljišta na korišćenje",
        ConsolidationDocument: "Rešenje / Izvod o raspodeli komasacione mase",
        OwnershipOutCadastreDocument:
          "Rešenje / Izvod iz katastra ili zemljišnih knjiga",
        LandEvidenceTitle: "Dokaz prava korišćenja",
        LandEvidenceDesc: `Priložite skeniran ili fotografisan dokument.
        Dokument možete dodati direktno sa računara ili fotografisati direktno svojim mobilnim telefonom.`,

        LandCADASTRE_OWNERSHIPTitle: "U Katastru",
        LandCADASTRE_OWNERSHIPDesc:
          "Zemljište je u vlasništvu i upisano je u Katastar",
        LandOWNERSHIP_OUT_CADASTRETitle: "Nije uređeno u Katastru",
        LandOWNERSHIP_OUT_CADASTREDesc:
          "Vlasništvo nije u potpunosti uređeno u Katastru",
        LandCONSOLIDATIONTitle: "Iz komasacije",
        LandCONSOLIDATIONDesc:
          "Zemljište je u vlasništvu po osnovu procesa komasacije.",
        LandLEASETitle: "U zakupu ili ustupljeno zemljište od fizičkog lica",
        LandLEASEDesc: "Zemljište je u zakupu ili ustupljeno od fizičkog lica.",
        LandLEASE_LEGAL_ENTITYTitle:
          "U zakupu ili ustupljeno zemljište od pravnog lica",
        LandLEASE_LEGAL_ENTITYDesc:
          "Zemljište je u zakupu ili ustupljeno od pravnog lica.",
        LandLEASE_CONSOLIDATIONTitle: "Zakupljeno zemljište iz komasacije",
        LandLEASE_CONSOLIDATIONDesc:
          "Zemljište je u zakupu po osnovu procesa komasacije.",
        // Land part
        ShowLandPartEvidence: "Prikaži delove parcele",
        PartLand: "Delovi parcele",
        PartNumber: "Br. dela",
        PartSurface: "Površina m2",

        // Statement
        StatementTitle: "Izjava člana domaćinstva da jeste član domaćinstva",
        StatementDesc: `Da bi član domaćinstva bio upisan u registar, potrebno je da lično da izjavu da je član porodičnog poljoprivrednog domaćinstva.
        Izjava se može potpisati elektronski ili u papirnoj formi i priložiti skenirana ili fotografisana.`,
        StatementTitle_eid: "Izjava u elektronskoj formi",
        StatementDesc_eid: `Član ima eID - pošalji izjavu u elektronskoj formi na potpisivanje.
        Ovako potpisana, izjava je automatski prihvaćena.`,
        StatementTitle_attachments: "Izjava u papirnoj formi",
        StatementDesc_attachments: `Član nema eID - izjava će biti odšampana, skenirana i priložena.
        Ovakvu izjavu mora proveriti nadležni organ.`,
        StatementEvidenceTitle: "Izjava člana domaćinstva u papirnoj formi",
        StatementEvidenceDesc:
          "Ako član domaćinstva nema eID ili drugi digitalni sertifikat priložite:",
        PaperStatementDesc: "Potpisanu izjavu da je član domaćinstva",
        PaperStatementLandDesc:
          "Potpisanu izjavu o ustupanju zemljišta člana domaćinstva",
        PhotoStatementDesc: "Fotografiju ili sken obe strane lične karte člana",
        PrintStatementForm: "Odštampajte formular za izjavu",
        StatementEIDTitle: "Elektronska izjava člana domaćinstva",
        StatementEIDDesc: `Obavestite člana da ćete mu upravo poslati izjavu na potpisivanje.
        Vreme čekanja na potpis je 15 minuta.`,
        StatementEIDDescPENDING_SIGNATURE: "Izjava je poslata na potpisivanje.",
        StatementEIDDescSIGNED: "Izjava je potpisivanje.",
        MemberPersonalIdentityNumber: "JMBG člana",
        SendEIDStatement: "Pošaljite izjavu na elektronski potpis",
        EvidenceSignStatusPENDING_SIGNATURE: "čeka na potpis",
        EvidenceSignStatusSIGNED: "potpisano",
        LandNotVerifiedInfo: `Pravo korišćenja nad parcelom je u procesu provere i biće dodato u Registar nakon provere Registratora.
        Priložene dokaze možete pogledati klikom na dugme "Prilozi" neposredno pored dugmeta za izmenu podataka.`,

        ////// LAND CULTURES //////
        LandCulture: "Biljna kultura",
        LandCulturesTitle: "Biljne kulture na parceli",
        LandCultureAdd: "Dodaj biljnu kulturu na parcelu",
        LandCultureEdit: "Izmeni podatke o kulturi",
        DeleteLandCulture: "Ukloni biljnu kulturu",
        DeleteLandCultureDesc:
          "Obratite pažnju da li nakon brisanja ispunjavate uslove za aktivan status. Da li ste sigurni da želite da uklonite biljnu kulturu?",
        Culture: "Kultura",
        CultureSurface: "Površina pod kulturom",
        NumberOfSeedlings: "Broj sadnica/stabala",
        ProductionType: "Vrsta proizvodnje",
        Irrigation: "Navodnjava se",
        HailProtectionSurface: "Protivgradna zaštita na",
        NumberOfThrees: "Broj stabala",

        ////// ANIMALS //////
        AnimalInfoDesc: `Podaci o fondu životinja se prikazuju iz registra Uprave za veterinu.
        Ukoliko su podaci nepotpuni ili nisu tačni obratite se nadležnoj veterinarskoj službi kako biste uredili stanje u registru.
        Za svaki HID neophodno je upisati način uzgoja.`,
        CheckAnimalDetails: "Pogledaj detalje iz uprave za veterinu",
        HidShort: "HID",
        Hid: "Identifikacioni broj (HID)",
        HidHelperLabel: "Hid mora imati 12 karaktera",
        AnimalName: "Naziv životinja",
        AnimalGroup: "Grupa životinja",
        AnimalCount: "Broj životinja",
        AnimalCountOrganic: "Broj životinja (Organska proizvodnja)",
        AnimalCountConventional: "Broj životinja (Konvencionalna proizvodnja)",
        AnimalStatus: "Status u okviru PG",
        AnimalProductionType: "Način uzgoja",
        AnimalDescriptionAndCount: "Opis i obim uzgoja",
        AnimalCONVENTIONAL: "Konvencionalni",
        AnimalORGANIC: "Organski",
        Registered: "Prijavljen",
        Unregistered: "Neprijavljen",
        SHEEP: "Ovce i koze",
        PIG: "Svinje",
        CATTLE: "Goveda",
        HORSE: "Konji, magarci, mule i mazge",
        BIRTS: "Živina i ptice",
        BEES: "Pčelinje društvo - košnice",
        FISH: "Ribe",
        OTHER: "Životinje ostale",
        CUSTOM: "Ostale životinje",
        RequiredFieldsMissingAnimals: `Nije unet broj životinja po načinu uzgojа.  
        Popunite polja "Broj - organski uzgoj" i "Broj - konvencionalni uzgoj"`,
        AnimalsRecapitulationTitle: "Pregled prijavljenog životinjskog fonda",
        EnrolledInUPV: "Upisano u UPV",
        OrganicFarming: "Organski uzgoj",
        ConventionalFarming: "Konv. uzgoj",
        TotalAnimalsCount: "Ukupno životinja",
        AddCustomAnimal: "Dodaj grupu ostalih životinja",
        DeleteAnimal: "Ukloni životinju",
        DeleteAnimalDesc: "Da li ste sigurni da želite da uklonite životinju?",
        AnimalHidsSection: "Registrovani HID-ovi u Upravi za veterinu",
        AnimalHidsSectionInfo: `Podaci o fondu životinja se preuzimaju iz registra Uprave za veterinu samo za već registrovana Poljoprivredna gazdinstva.
        Molimo da unesete sve HID-ove registrovane u Upravi za veterinu koji će biti prijavljeni na Vaše gazdinstvo.
        Nakon dobijanja Rešenja o upisu PG, potebno je da se ponovo prijavite u sistem i proverite podatke o životinjskom fondu.`,
        AnimalHidsSectionButton: "Dodaj HID",
        AnimalHidsList: "Lista HID-ova",
        RemoveHid: "Ukloni HID",
        RemoveHidDesc: "Da li ste sigurni da želite da uklonite HID",

        ////// ERROR //////
        ErrorTitle: "Došlo je do greške",
        ErrorMessage: "Došlo je do neočekivane greške. Pokušajte ponovo.",
        ErrorCADASTRIAN_NUMBER_NOT_FOUND:
          "Tražena parcela nije pronađena u katastru",
        ErrorFARM_NOT_FOUND: "Poljoprivredno gazdinstvno nije pronađeno",
        ErrorFARM_NOT_READY_FOR_RENEWAL:
          "Poljoprivredno gazdinstvno ne ispunjava uslov za obnovu",
        ErrorFARM_NOT_RENEWED: "Poljoprivredno gazdinstvno nije obnovljeno",
        ErrorUNSUPPORTED_MIME_TYPE: "Format fajla nije podržan",
        ErrorLEGAL_REPRESENTATIVE_CURRENT_USER:
          "Vi ste već odgovorna osoba za uneti BPG",
        ErrorLEGAL_REPRESENTATIVE_CHANGE_PHYIS_PERSON:
          "Promena zakonskog zastupnika moguća je samo za pravna lica",
        ErrorHOLDER_CHANGE_LEGAL_ENTITY:
          "Privremeni nosilac je dozvoljen samo za porodična gazdinstva",
        ErrorFARM_REG_NUMBER_SIZE_12:
          "Broj poljoprivrednog gazdinstva mora imate 12 cifara",
        ErrorMEMBER_PERSONAL_IDENTITY_NUMBER_EXIST:
          "Član već postoji u nekom od poljoprivrednih gazdinstava",
        ErrorHOLDER_PERSONAL_IDENTITY_NUMBER_EXIST:
          "Član je nosilac gazdinstva",
        ErrorUSED_SURFACE_BIGGER_THAN_CADASTRE_SURFACE:
          "Površina udela u parceli veća od površine parcele",
        ErrorMEMBER_CANNOT_ACT_AS_HOLDER: "Izabrani član ne može biti nosilac",
        ErrorLAND_UNVERIFIED_MEMBER:
          "Ne može se dodati zemlja članu koji nije verifikovan",
        ErrorDATABOX_NOT_FOUND: `Sanduče za dostavu dokumenta nije pronađeno. 
        Molimo prijavite se na portal eUprava (www.euprava.gov.rs) a zatim ponovo pokušajte.`,
        ErrorEDELIVERY_UNAVAILABLE:
          "Servis za slanje trenutno nije dostupan, molimo pokušajte ponovo.",
        ErrorLAND_PLANT_CULTURE_BIGGER_THAN_LAND_USED_SURFACE:
          "Površina biljnih kultura na parceli je veća od površine udela u parceli",
        ErrorCADASTRE_NUMBER_WRONG_FORMAT:
          'Broj kat. parcele je pogrešnog formata. Broj može da sadrži samo cifre, npr. "123456789" ili cifre i podbroj nakon "/" npr. "123456/12".',
        ErrorBIRTH_DATE_INVALID: "Nosilac mora biti stariji od 18 godina",
        ErrorAT_LEAST_ONE_ACTIVITY_REQUIRED: "/",
        ErrorLEGAL_FORM_OTHER_AGRICULTURAL_PRODUCT_PROCESSING_NOT_ALLOWED: "/",
        ErrorFARMS_IN_REGISTRATION_STATUSES_CAN_NOT_CHANGE_HOLDER:
          "Nije moguće promeniti nosioca dok je proces registracije u toku.",
        ErrorLAND_APPROVAL_IN_ADMINISTRATIVE_PROCEDURE:
          "Nije moguće menjati parcelu koja se nalazi u postupku verifikacije.",
        ErrorMEMBER_APPROVAL_IN_ADMINISTRATIVE_PROCEDURE:
          "Nije moguće menjati člana koji se nalazi u postupku verifikacije.",
        ErrorHOLDER_HAVE_TO_BE_MEMBER: "Nosioc mora biti član gazdinstva.",
        ErrorCANNOT_RENEW: "Gazdinstvno ne moze biti obnovljeno.",
        ErrorBANK_CODE_NOT_FOUND: "Nije pronađena banka sa unetom šifrom.",
        ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_INVOICE_ITEM_SUM:
          "Nemate dovoljno goriva na računima",
        ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_LAND_PLANT_CULTURE_ALLOWED:
          "Prekoračili ste maksimalnu količinu goriva za biljnu kulturu na parceli",
        ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_LAND_ALLOWED:
          "Prekoračili ste maksimalnu količinu goriva za parcelu",
        ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_FARM_ALLOWED:
          "Prekoračili ste maksimalnu količinu goriva u tekućoj godini",
        ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_THAN_ZERO:
          "Količina za koju se zahteva refakcija mora biti veća od nule",
        ErrorBANK_NOT_ACTIVE: "Banka kojoj pripada račun nije aktivna",
        ErrorWRONG_BANK_ACCOUNT_NUMBER:
          "Broj računa nije ispravan, molimo proverite",
        ErrorWRONG_BANK_CODE: "Banka sa kodom {{ bankCode }} ne postoji",
        ErrorHOLDER_APPROVAL_ALREADY_EXIST:
          "Za ovo gazdinstvo već postoji podnet zahtev za promenu nosioca koji je trenutno u obradi",
        ErrorREFRACTION_REQUEST_SENT_TO_HERMES:
          "Nije moguće obrisati zahtev jer je već otvoren predmet, možete samo izvršiti izmenu i ponovno podneti",
        ErrorHISTORY_AT_DAY_BEFORE_REGISTRATION_DATE:
          "Gazdinstvo za koje su traženi podaci nije bilo registrovano u trenutku za koji se traži dostavljanje podataka",
        ErrorAccessDeniedInfo: `Niste ovlašćeni da pristupite podacima za traženo poljoprivredno gazdinstvo.
         Molimo proverite da li ste ulogovani sa ispravnim kredencijalima.`,

        AppError: `Pristup servisu:
        {{app}}
        trenutno nije aktivan.
        Molimo pokušajte kasnije.`,
        RGZ_UNAVAILABLE: "Katastar nepokretnosti - Republički geodetski zavod",
        APR_UNAVAILABLE: "APR",

        StartChanges: "Započni promene",
        AdminProcessingRequest: "Admin trenutno obrađuje vaš zahtev",

        ApprovalStatusAPPROVED: "Obrađeno",
        ApprovalStatusREJECTED: "Upravni postupak",
        ApprovalStatusPENDING: "Čeka na obradu",
        ApprovalStatusDECISION_SEND: "Poslato rešenje",
        ApprovalStatusWAITING_COMPLEMENT: "Čeka dopunu",
        ApprovalStatusCOMPLEMENTED: "Dopunjen",

        WaitingComplementText:
          "Potrebno je da se izvrši izmena ili dopuna informacija u skladu sa pozivom koji Vam je poslat",
      },
    },
    "sr-RS-Cyrl": {
      translation: {
        ////// BASIC //////
        Yes: "Да",
        No: "Не",
        Back: "Назад",
        Confirm: "Потврди",
        Next: "Даље",
        Ok: "У реду",
        Attachments: "Прилози",
        Cancel: "Откажи",
        Edit: "Измени",
        Delete: "Уклони",
        Remove: "Избриши",
        Search: "Претражи",
        or: "или",
        NumberShort: "Бр.",
        TryAgain: "Покушајте поново",
        Important: "ВАЖНА ИНФОРМАЦИЈА!",
        eRPGLong: "Електронски регистар пољопривредних газдинстава",
        FooterFreeCall: "Бесплатан позив за подршку:",
        PENDING: "У припреми",
        PENDING_APPROVAL: "Чека одобрење",
        ACTIVE: "Активно",
        PASSIVE: "Пасивно",
        DELETED: "Обрисано из регистра",
        COMMERCIAL: "Комерцијално газдинство",
        NON_COMMERCIAL: "Некомерцијално газдинство",
        M: "Мушки",
        F: "Женски",
        CONVENTIONAL: "Конвенционална",
        ORGANIC: "Органска",
        FreeTextPlaceholder: "Слободан текст...",
        ChoosePlaceholder: "Изабери...",
        Title: "Наслов",
        Note: "Напомена",
        Save: "Сачувај",
        MissingAttachment: "Недостаје прилог",
        ContinueWithoutFarm: "Настави без избора пољопривредног газдинства",
        MobileAppUnavailable:
          "Приступ еРПГ је тренутно омогућен преко таблет уређаја и рачунара",
        RequiredFieldsMissing: "Нису унети подаци за сва обавезна поља.",
        RequiredFieldError: "Морате попунити поље",
        Statement: "Изјава сагласности",
        NoAccessInfo: "Немате право приступа овој страници",
        CancelRequestInfo: "Ваш захтев је отказан",
        ReturnToDashboard: "Вратите се на почетну страну",
        CreatedAtTime: "Време креирања",
        Actions: "Акције",
        Details: "Детаљи",
        Quit: "Одустани",
        Supplier: "Добављач",
        SupplierForm: "Правна форма добављача",

        ////// HELP //////
        HelpTitle: "Помоћ за кориснике",
        HelpDesc:
          "Погледајте видео презентацију или прочитајте илустровано упутство за поцедуру измене података и обнову пољопривредног газдинства.",
        HelpTEXT: "Прочитајте упутство",
        HelpVIDEO: "Погледајте кратко видео упутство",
        HelpAQ1:
          "<strong>Сетвену структуру је потребно унети за све парцеле на којима вршите узгој. Сасвим је у реду што вам се тренутно не приказује ниједна биљна култура, ови подаци нису преузети из старог Регристра.</strong>",
        HelpAQ2:
          "<strong>Ако не видите парцелу у РПГ</strong>, слободно је можете самостално додати. Уколико је земља у вашем власништву, опцијом “Додај парцелу” у делу “Земљишни фонд и биљне културе”, додајте парцелу избором једне од опција. <strong>Уколико желите да додате земљу члана</strong>, учините то додавањем парцеле у делу “Чланови”, на члану чија је земља.",
        HelpAQ3:
          "Уколико је <strong>ваша парцела коју желите да додате уписана у Републичком геодетском заводу (РГЗ - Катастар)</strong> опцијом “Земља у власништву уписана у катастру” додајте парцелу и то без достављања листа непокрености. Изаберите вашу КО и КП и након тога ће вам бити понуђене парцеле које можете да додате.",
        HelpAQ4:
          "<strong>Земљу у закупу</strong> можете додати уз прилагање (сликање) уговора о закупу <strong>(без обзира да ли је уговор оверен код нотара)</strong>. Не заборавите, земља која је у закупу од државе је аутоматски додата у Ваш земљишни фонд и не треба је додавати ручним уносом. Уколико не видите земљу у закупу коју сте имали унету у претходни Регистар, унесите је поново.",
        HelpAQ5:
          "Уколико видите <strong>парцеле које не би требало да буду у вашем РПГ</strong> јер их не обрађујете у оквору Вашег ПГ, или из било ког другог разлога, избришите их из дела “Земљишни фонд и биљне културе”.",
        HelpAQ6:
          "<strong>Уколико не видите ХИД</strong> обратите се ветеринарској станици како бисте затражили да се на ваш ХИД упише број вашег ПГ.",
        HelpAQ7:
          "<strong>За све ХИД-ове</strong> које видите и који се преузимају из базе Управе за ветерину, потребно је да унесете податак о томе <strong>колико животиња се узгаја конвенционалним, а колико органским узгојем.</strong>",

        ////// CONFIRM DIALOG //////
        ConfirmTitleSave: "Сачувано!",
        ConfirmDescSave: "Унети подаци су успешно сачувани.",
        ConfirmTitleEdit: "Измењено!",
        ConfirmDescEdit: "Подаци су успешно измењени.",
        ConfirmTitleRequest: "Успешно!",
        ConfirmDescRequest: "Захтев је успешно послат.",
        ConfirmTitleSuccessGeneralRequest: "Успешно!",
        ConfirmDescSuccessGeneralRequest:
          "Документ ће бити послат у ваше еУправа сандуче.",
        ConfirmTitleRefractionPost: "Сачувано!",
        ConfirmDescRefractionPost: `Ваш захтев за рефакцију је успешно сачуван и послат на обраду.
          Након обраде, Решење ће Вам бити достављено у Ваше еСандуче.`,

        ConfirmDescSaveValidation: `Унети подаци су успешно сачувани.
        Подаци ће бити унети у регистар након
        провере од стране Регистратора.`,
        ConfirmDescEditValidation: `Подаци су успешно измењени.
        Измењени подаци ће бити унети у регистар
        након провере од стране Регистратора.`,
        ConsentPageDialogTitle: "Захтев поднет",
        ConsentPageDialogDescription:
          "Успешно сте завршили процес подношења захтева еАграру за доставу података о пољопривредном газдинству ка Агро бази НИС а.д.",
          ConsetnPageDialogConfirmButton:'ОК',

        ////// LOGIN, REGISTRATION //////
        Login: "Пријави се",
        LoginTitle: "Пријавите се преко портала за електронску идентификацију",
        LogOut: "Одјави се",
        ChooseFarm: "Одаберите пољопривредно газдинство",
        RegisterSearch: "Претрага регистра",
        FarmRegistrationInProgress: "Газдинство у регистрацији",

        ////// HEADER //////
        Settings: "Подешавања",
        "sr-RS-Cyrl": "Ћирилица",
        "sr-RS-Latn": "Латиница",
        Home: "Почетна",
        eEncouragement: "еПодстицаји",
        eInbox: "еСандуче",
        Help: "Помоћ",
        Contact: "Контакт",
        ChangeOrg: "Промени ПГ",
        MyAccount: "Мој налог",
        OrgNumberShort: "БПГ",
        ChangeFarm: "Промени ПГ",

        ////// DASHBOARD //////
        DashboardInfoTitle: "Обавезна обнова пољопривредних газдинстава",
        DashboardInfoDesc:
          "У току је обавезна обнова пољопривредних газдинстава. Да би газдинство остало у активном статусу и могло да конкурише за подстицаје Министарства, неопходно је да изврши обнову.",
        AHChangeInfoTitle: "Преглед и промена података о газдинству",
        ChangeInfoTitle: "Преглед и промена података",
        AHChangeInfoDesc:
          "Сваку промену у подацима о пољопривредном газдинству неопходно је да пријавите Регистру у року од 30 дана од наступања промене.",
        AHChangeInfoTooltip:
          "Газдинство је у процесу одобравања и не може да се мења",
        AHRenewalTitle: "Обнова пољопривредног газдинства",
        AHRenewalDesc:
          "Како бисте задржали АКТИВАН статус свог пољопривредног газдинства неопходно је да проверите податке о свом газдинству и извршите обнову.",
        AHRegisterTitle: "Регистрација новог газдинства",
        AHRegisterDesc:
          "Региструјте ново газдинство на своје име или на име компаније чији сте законски заступник.",
        AHRegisterTooltip:
          'Покренут је процес регистрације новог газдинства. За измену података, кликните на "Преглед и промена података о газдинству".',
        AHRegistryDataTitle: "Извод из Регистра Пољопривредних Газдинстава",
        AHRegistryDataDesc:
          "Преузмите одмах информације о свим подацима или поднесите захтев за доставу овереног Извода из РПГ.",
        AHChangeStatusTitle: "Промена статуса газдинства",
        AHChangeStatusDesc:
          "Поднесите захтев за превремени прелазак у активан статус уз достављање неопходне документације.",
        AHDeleteTitle: "Брисање пољопривредног газдинства",
        AHDeleteDesc: "Поднесите захтев за брисање газдинства из регистра.",
        AHChangePPHolderTitle: "Промена носиоца породичног ПГ",
        AHChangePPHolderDesc:
          "Поднестите захтев за промену носиоца Вашег пољопривредног газдинства тако што ћете именовати неког од чланова за новог носиоца и приложити сагласност осталих чланова.",
        AHChangePPHolderTooltip:
          "Није могуће променити носиоца док је процес регистрације у току.",
        AHSetTempHolderTitle: "Постављање привременог носиоца ПГ",
        AHSetTempHolderDesc:
          "У случају смрти тренутног носиоца, породичног ПГ било који члан може преузети уклогу привременог носиоца до окончања оставинског поступка, уз прилагање сагласности осталих чланова.",
        AHChangeLEHolderTitle: "Промена одговорног лица правног субјекта",
        AHChangeLEHolderDesc:
          "Промените одговорно лице правног субјекта на ког је регистровано пољопривредно газдинство.",
        AHCaseUpdateTitle: "Допуна предмета и достављање поднесака",
        AHCaseUpdateDesc:
          "Поднесите општи захтев или доставите документа, допуну предмета и сл.",
        AHBasicRequestsTitle: "ОПШТИ захтеви",
        AHBasicRequestsDesc:
          "Поднесите општи захтев или доставите документа, допуну предмета и сл.",
        AHRejectObjectionTitle: "Подношење жалби и одустанак од приговора",
        AHRejectObjectionDesc:
          "Убрзајте процес пуноснажности донетог решења изричитим одустанком од приговора у предвиђеном року.",

        BasicInfo: "Основни подаци",
        MembersTitle: "Чланови",
        MembersTitleOrdered: "2. Чланови",
        LandTitle: "3. Земљишни фонд и биљне културе",
        LandTitleOrdered: "3. Земљишни фонд и биљне културе",
        AnimalsTitle: "4. Фонд животиња",
        AnimalsTitleOrdered: "4. Фонд животиња",
        SaveChanges: "Сачувај измене",
        AHChangeInfoTitleShort: "Промена података о ПГ",
        AHRenewalTitleShort: "Обнова ПГ",
        AHRegisterTitleShort: "Регистрација новог ПГ",
        Renew: "Обнова",
        RenewOrdered: "5. Обнова",

        ////// PAGE //////
        PageTitleCONSENT: "ЗАХТЕВ ЗА ДОСТАВЉАЊЕ ПОДАТАКА",
        PageTitleSearch: "ПРЕТРАГА ПОЉОПРИВРЕДНИХ ГАЗДИНСТАВА",
        PageTitleEDIT: "Преглед и промена података о газдинству",
        PageTitleNEW: "Регистрација",
        PageTitleRENEW: "Обнова",
        PageTitleSTATEMENTS: "Изводи и потврде",
        PageTitleSTATUS_CHANGE: "Промена статуса",
        PageTitleDELETE: "Брисање из регистра",
        PageTitleCHANGE_HOLDER_PP: "ПРОМЕНА НОСИОЦА ПГ",
        PageTitleSET_TEMP_HOLDER: "ПОСТАВЉАЊЕ ПРИВРЕМЕНОГ НОСИОЦА ПГ",
        PageTitleCHANGE_HOLDER_LE: "ПРОМЕНА ОДГОВОРНОГ ЛИЦА",
        PageTitleGENERAL_REQUEST: "Подношење захтева",
        PageTitleCASE_UPDATE: "Допуна предмета",
        PageTitleCOMPLAINT: "Подношење жалби и одустанак од приговора",

        ////// NIS //////
        NisRequest: "Захтев за достављање података НИС-у",
        NisRequestInfo:
          "Захтевам да еАграр изврши издавање следећих наведених података: Број пољопривредног газдинства, Име и презиме носиоца пољопривредног газдинства, Тип газдинства: Физичко лице/Правно лице, Општину на којој је регистровано пољопривредно газдинствo, Емаил адресу, Укупну површину земљишта под биљним културама на захтевани дан , Укупну површину земљишта под биљним кутлтурама погодну за субвенције на захтевани дан, за потребе коришћења НИС Агро програма лојалности и достави ми наведене податке на адресу НИС-а. Подаци се не могу користити у друге сврхе.",

        ////// RENEWAL //////
        RenewTitle: "Обнова - БПГ: {{number}}",
        RenewTitleOrdered: "5. Обнова - БПГ: {{number}}",
        RenewInfoTitle: "Изјава о сагласности са унетим подацима",
        RenewInfoDesc:
          "Под пуном кривичном и материјалном одговорношћу, као носилац породичног пољопривредног газдинства, односно као одговорно лице пољопривредног газдинства, изјављујем:",
        RenewInfoDesc1:
          "да сам пре попуњавања обрасца пажљиво прочитао и разумео упутства за попуњавање образаца захтева за упис, обнову регистрације и промену података,",
        RenewInfoDesc2:
          "да су сви наведени и уписани подаци у Регистру тачни и истинити,",
        RenewInfoDesc3:
          "да је сва документација коју сам приложио веродостојна, као и да су сви подаци које садржи тачни и истинити,",
        RenewInfoDesc4:
          "да ћу омогућити инспекцијски надзор на пољопривредном газдинству,",
        RenewInfoDesc5:
          "да су пријављене и уписане све катастарстке парцеле на којима се обавља пољопривредна производња у складу са прописима.",
        RenewData: "Обнови газдинство",
        AlreadyRenewedTitle:
          "Обнова је већ извршена у овој календарској години",
        AlreadyRenewedDesc: `Пољопривредно газдинство је обновљено {{date}} 
        Следећа обнова је потребна следеће календарске године.`,

        RenewalConditionsTable: "Преглед испуњености услова за обнову",
        CountrysideTourism: "Сеоски туризам",
        AtLeastOnePlantCultureOrAnimal: "Пољопривредна производња",
        PlantCulture: "Узгој биљних култура",
        AnimalCulture: "Узгој животиња",
        AllowedAprCodes: "Прерада пољопривредних производа",
        TechnicalPrerequisiteCondition: "Технички предуслови",
        BasicInfoData: "Попуњени основни подаци",
        AnimalNumberData: "Унети подаци о броју животиња према врсти узгоја",
        UpzCondition: "Пријављене културе на целој површини из УПЗ",
        RenewalFarmStatus: "Статус газдинства дозвољава обнову",
        HasEDelivery: "Има активно еСандуче",
        HasEmailOrPhoneNumber: "Има унете контакт податке",
        ReadyForRenewal: "Испуњава услов за обнову",

        ////// BASIC INFO //////
        BasicInfoTitleOrdered: "1. Основни подаци",
        OrgNumberLong: "Број пољопривредног газдинства",
        Status: "Статус",
        StatusPG: "Статус ПГ",
        PassiveStatusReason: "Разлог пасивности",
        RenewalDate: "Датум обнове",
        ActiveStatusDays: "Време трајања активног статуса",
        RegistrationDate: "Датум регистрације",
        LatestChangeDate: "Датум последње измене",
        OrganizationalForm: "Организациони облик",
        MembersCount: "Број чланова ПГ",
        ArgHolderInfoTitleOrdered: "1.1. Подаци о носиоцу",
        ArgHolderEdit: "Измени податке о носиоцу ПГ",
        CreateFarm: "Региструјте пољопривредно газдинство",
        StartFarmCreate: "Започните процес регистрације ПГ",
        RequestFarmRegistration: "Поднеси захтев за регистрацију",
        ConfirmRegistrationTitle: "Захтев је успешно поднет",
        ConfirmRegistrationDesc:
          "Успешно сте поднели захтев за регистрацију који је на провери од стране верификатора. Ускоро ћете бити обавештени о статусу захтева.",
        SelectHolderType: "Изаберите тип пољопривредног газдинства:",
        HolderTypephysicalPerson: "Носилац газдинства је физичко лице",
        HolderTypelegalEntity: "Носилац газдинства је правно лице",
        AskDate: "Датум упита",
        HolderType: "Тип носиоца газдинства",
        HolderFirstName: "Име носиоца",
        HolderLastName: "Презиме носиоца",
        HolderTitle: "Назив носиоца",
        HolderMunicipality: "Општина седишта ПГ",
        ConsentRequestDate: "Датум за који се шаљу подаци",
        UsedSurfaceConsent: "Укупна површина под културама на достављени датум",
        TotalCultureConvinientSurface:
          "Укупна површина под културама која испуњава услов за субвенције на достављени датум",
        RequestForDataSubmission: "Захтев за достављање података",
        RequestForDataSubmissionNIS: "Захтев за достављање података НИС",
        Request: "Захтев",
        RequestSubmittionInfo: `Захтевам да еАграр изврши издавање следећих наведених података: Број пољопривредног газдинства, Назив носиоца пољопривредног газдинства, ПИБ пољопривредног газдинства, Тип газдинства: Физичко лице/Правно лице, Општину на којој је регистровано пољопривредно газдинствo, Емаил адресу, Укупну површину земљишта под биљним културама на захтевани дан , Укупну површину земљишта под биљним кутлтурама погодну за субвенције на захтевани дан, за потребе коришћења {{provider}} Агро програма лојалности и достави ми наведене податке на адресу {{provider}}-а. Подаци се не могу користити у друге сврхе.`,

        ////// STATEMENT AND CERTIFICATES //////
        StatementsAndCertificatesTitle: "Изводи и потврде",
        StatementSectionTitle:
          "Извод података из Регистра пољоприведних газдинстава",
        RequestStatementTitle:
          "Затражите извод података или потврду о статусу.",
        RequestStatementDesc:
          "Овде можете преузети информативни извод о свим подацима тренутно уписаним у РПГ за Ваше пољопривредно газдинство или поднети захтев да Вам се достави званичан оверени извод. Такође, можете поднети захтев за достављање оверене потврде о статусу пољопривредног газдинства.",
        SubmitRequest: "Поднеси захтев",
        CancelRequest: "Одустани од захтева",
        ConsentPageNoAccess:
          "Нисте овлашћени за преузимање података о пољопривредном газдинству број",
        CurrentStatusCertificateTitle:
          "Потврда о тренутном статусу пољопривредног газдинства",
        DurationOdTheActiveStatusCertificateTitle:
          "Потврда о трајању активног статуса пољопривредног газдинства за",
        StatementType: "Врста извода",
        DeliveryType: "Начин доставе",
        StatementPurpose: "Сврха извода",
        DeliveryTypeINBOX: "Достава овереног извода",
        DeliveryTypeDOWNLOAD: "Преузимање неовереног извода",
        CertificatePurpose: "Сврха потврде",
        AtDate: "На дан",
        AtDateHelper:
          "Могући датуми су 01.09.2023. или датуми после 27.02.2024.",

        ////// PESTICIDE REFACTIONS //////
        PesticideProcurements: "Евиденција куповине пестицида",
        PlantProtectionProduct: "Средство за заштиту биља",
        PlantProtectionProductContraint:
          "Морате унети датум куповине производа пре избора средства за заштиту биља",
        Pesticides: "Пестициди",
        AddPesticidePurchase: "Додај куповину пестицида",
        PesticideDetails: "Детаљи куповине пестицида",
        PesticideProcurementsDesc:
          "Поднесите нови захтев за евиденцију куповине пестицида",
        Category: "Категорија",
        Producer: "Произвођач",
        Quantity: "Количина",
        UnitOfMeasure: "Јединица мере",
        PurchaseDate: "Датум куповине",
        DeletePesticideDesc:
          "Да ли сте сигурни да желите да уклоните изабрану куповину?",

        ////// STATUS CHANGE //////
        RequestStatusChangeTitle:
          "Поднесите захтев за превремени прелазак у активан статус",
        RequestStatusChangeDesc:
          "Овде можете поднети захтев за превремени прелазак у активан статус газдинства које је у пасивном статусу уз прилагање доказа за основ преласка у активно стање.",
        StatusChangeTitle: "Захтев за промену статуса",
        StatusChangeToActiveRequest: "Захтев за прелазак у активно стање",
        RequestDescription: "Опис захтева",
        UploadedFiles: "Приложена документа",
        RequestComplementStatusChange: "Поднеси захтев за допуну регистрације",
        PermanentHolder: "Промена у сталног носиоца",
        PermanentHolderDesc: `Да бисте постали стални носилац, потребно је да:

        • Буде завршен оставински поступак и спроведена промена у Катастру непокретности,
        • Уколико потврдите да желите да будете стални носилац, све парцеле које се воде на претходног носиоца
         као и парцеле које је он претходно као члан уступио ПГ ће бити уклоњене из ПГ и морате их поново унети. 
        • Дугме Да за сагласност, дугме Не за одустајањe`,

        ////// INVOICES //////
        Invoices: "Рачуни",
        ReplaceInvoice: "Допуни податке о рачуну",
        DeleteInvoice: "Избриши рачун",
        DeleteInvoiceDesc: "Да ли сте сигурни да желите да избришете рачун?",
        FuelInvoices: "Рачуни за гориво",
        InvoicesDesc:
          "Овде можете прегледати све издате фискалне рачуне за гориво купљено за потребе Вашег газдинства или додати фактуре и увозне фактуре за гориво купљено у велепродаји или увезено.",
        InvoiceNumber: "Број рачуна",
        InvoiceDate: "Датум рачуна",
        SellerTaxId: "ПИБ продавца",
        InvoiceAdd: "Додај фактуру",
        NewInvoice: "Додавање новог рачуна",
        NewInvoiceDesc:
          "Унесите податке са рачуна, додајте ставке и након тога приложите потребну документацију",
        AddInvoiceItems: "Приложите ставке рачуна",
        FuelType: "Тип горива",
        Liter: "Количина у литрима",
        LiterHelpText: "Количина у литрима мора бити мања од {{value}} л",
        InvoiceItems: "Ставке рачуна",
        InvoiceUsed: "Искоришћен",
        InvoiceItemAdd: "Додај ставку",
        InvoiceItemsDesc:
          "Приложите ставке рачуна и након тога приложите потребну документацију",
        InvoiceEvidenceTitle: "Приложите документа",
        InvoiceEvidenceDesc:
          "Приложите оригиналну фактуру са СЕФ-а или фотографију/скен велепродајног или увозног рачуна, као и доказ о извршеном плаћању.",
        InvoiceNotApprovedInfo: "Рачун је у процесу провере.",
        TotalInvoiceFuels: "Укупно искоришћено горива / укупно на фактурама",
        InvoicesPageInfo: `Ова страница намењена је евиденцији фискалних рачуна и фактура за гориво и преко ње се не врши подношење Захтева за рефакцију.
        
        На овој страници можете видети листу свих фискалних рачуна аутоматски преузетих из система еФискализације који на себи имају обавезне информације о Пољопривредном Газдинству.
        
        Такође, на овој страници можете додати рачуне/фактуре за гориво купљено у велепродаји као и увозне фактуре за гориво купљено из увоза.`,
        InvoicesPageInfoBox:
          "Овде можете проверити своје фискалне рачуне или додати нову велепродајну или увозну фактуру. Фактуре које додате, морају бити верификоване од стране система како бисте могли да их искористите за подношење захтева за рефакцију. Додавање рачуна не подразумева аутоматско подношење Захтева за рефакцију - да бисте поднели Захтев посетите <link1>ову страницу</link1>",
        InoviceType: "Тип фактуре",
        WholesaleInvoice: "Велепродајна фактура",
        ImportInvice: "Увозна фактура",
        FiscalInvoice: "Фискални рачун",
        NoFiscalInvoiceInfo:
          "Фискални рачун није могуће додати ручно, сви фисклани рачуни који на себи имају обавезне информације о Пољопривредном Газдинству се аутоматски преузимају из система еФискализације.",
        InvoiceDisclaimer: `Упознат/а сам да:
        - фактура коју овом приликом додајем МОРА БИТИ накнадно системски проверена да би се могла користи за подношење захтева за остваривање права на рефакцију плаћене акцизе у оквиру софтверског решења еАграр, 
        - додавање фактуре НЕ ЗНАЧИ и истовремено подношење захтева за остваривање права на рефакцију плаћене акцизе у оквиру софтверског решења еАграр.`,

        ////// REFACTION //////
        Refaction: "Рефакција",
        RefactionTitle: "Захтев за рефакцију акцизе",
        RefactionDesc:
          "Поднесите нови захтев за рефакцију плаћене акцизе на гориво или погледајте све своје претходно поднете захтеве.",
        RefactionPageInfoBox:
          "Овде можете поднети нови Захтев за рефакцију на основу постојећих рачуна или прегледати претходно поднете захтеве. Уколико сте гориво купили преко велепородајне фактуре или увезли, потребно је прво да додате ту фактуру на <link1>овој страници</link1>",
        RefactionNewRequestAdd: "Поднеси нови захтев за рефакцију",
        RefactionRequestAdd: "Сачувај захтев",
        RefactionRequestSubmit: "Поднеси захтев",
        NewRefactionRequest: "Подношење новог захтева за рефакцију",
        NewRefactionRequestDesc:
          "Изаберите рачуне за које желите да поднесете захтев за рефакцију, а затим дефинишите парцеле и биљне културе на које је утрошено гориво са одабраних рачуна",
        RefactionRequestDelete: "Брисање нацрта захтева",
        RefactionRequestDeleteDesc:
          "Да ли сте сигурни да желите да обришете нацрт захтева?",
        NoInvoicesForRefaction:
          "Немате ни један рачун за који може да се поднесе захтев за рефакцију",
        AddRefactionRequestItems: "Одаберите парцеле и културе",
        RequestItems: "Ставке захтева",
        RefactionRequestItemsTitle: "Парцеле и биљне културе",
        RefactionRequestItemsDesc:
          "Распоредите гориво са изабраних рачуна на земљишни фонд и биљне културе на којима је потрошено",
        DeleteItem: "Уклони парцелу",
        MaxLiterForCulture:
          "Максимална количина горива за ову биљну културу је {{value}} л",
        RemainLiters:
          "Преостала количина горива за ову парцелу и одабрану биљну културу је {{value}} л",
        ConfirmRefactionRequest:
          "Тренутно подносите: {{value}} ставку за рефакцију",
        RefactionStatusDRAFT: "У припреми",
        RefactionStatusSUBMITTED: "Примљен",
        RefactionStatusAPROVED: "Одобрен",
        RefactionStatusREJECTED: "Одбијен",
        RefactionStatusSENT: "Послат",
        RefractionStatusSuccessfullySubmitted:
          "Ваш захтев је запримљен под бројем: {{value}}",
        RefractionSuccessfullyDrafted:
          "Ваш захтев се налази у припреми. Потврдите захтев.",
        UsedFuelPreview: "Преглед искоришћеног горива",
        InvoiceLiterSum:
          "Укупно расположивих литара на основу изабраних рачуна",
        LiterSum:
          "Укупно искоришћених литара горива на основу ставки захтева за рефакцију",
        SummaryPreview:
          "Одабрани рачуни неће моћи да се користе у будућим захтевима за рефакцију горива без обзира на то да ли имају преостале неискоришћене количине горива",
        SubmitRefactionRequest: "Подношење захтева за рефакцију",
        SubmitRefactionRequestDesc:
          "Да ли сте сигурни да потврђујете подношење захтева?",

        ////// DELETE //////
        RequestForFarmDelete: "Захтев за брисање ПГ",
        DeleteTitle: "Брисање пољопривредног газдинства из Регистра",
        RequestDeleteTitle:
          "Поднесите захтев за брисање пољопривредног газдинства из РПГ",
        RequestDeleteDesc:
          "Овде можете поднети захтев за брисање газдинства из Регистра пољопривредних газдинстава уз образложење и евентуални доказ за основ Вашег захтева.",
        DeleteRequestFromRegister: "Захтев за брисање из регистра",

        ////// CHANGE HOLDER //////
        ChangeHolderTitle: "Промена носиоца ПГ",
        RequestChangeHolderTitle: "Промените носиоца породичног ПГ",
        RequestChangeHolderDesc:
          "Извршите промену носиоца породичног пољопривредног газдинства чији сте тренутно носилац и поставите једног од тренутних чланова ПГ као новог носиоца. Променом носиоца, сво земљиште пријављено на ПГ у тренутку промене на које сте као носилац имали право коришћења остаће и даље у оквиру газдинства.",
        NewHolderStatement: "Изјаву новог носиоца да прихвата да буде носилац",
        DownloadHolderStatement: "Преузмите формулар за изјаву носиоца",
        MembersStatementForChangeHolder:
          "Изјаву свих чланова да су чланови домаћинства новог носиоца",
        BankAccountRegisteredConfirmation:
          "Потврду из банке или уговор о отварању наменског рачуна.",
        ChangeHolderStatement:
          "Након прихватања Вашег захтева, тренутни наменски рачун ће бити избрисан из ПРГ и потребно је да нови носилац отвори нови наменски рачун и исти упише у Регистар ПГ.",
        NewFarmHolder: "Нови носилац ПГ",

        ////// CHANGE LEGAL REPRESENTATIVE //////
        ChangeLRTitle: "Промена одговорног лица правног субјекта",
        RequestChangeLRTitle:
          "Промените одговорно лице за ПГ регистровано на привредни субјект",
        RequestChangeLRDesc:
          "Поднесите захтев за именовање Вас као одговорног лица за пољопривредно газдинство које је регистровано на правно лице чији сте затупник.",
        NewLREvidence:
          "Доказ основа по ком захтевате да будете уписани као одговорно лице наведеног пољопривредног газдинства.",
        NewLRFirstName: "Име новог одговорног лица",
        NewLRLastName: "Презиме новог одговорног лица",
        NewLRtPIN: "ЈМБГ новог одговорног лица",

        ////// SET TEMP HOLDER //////
        TempHolderTitle: "Привремени носилац ПГ",
        RequestTempHolderTitle: "Именовање привременог носиоца",
        RequestTempHolderDesc:
          "Поднесите захтев за именовање Вас као привременог носиоца у случају смрти тренутног носиова породичног пољопривредног газдинства чији сте члан, а до окончања оставинског поступка и спровођења промена у катастру непокретности.",
        HolderDeathCertificate:
          "Извод из Матичне књиге умрлих тренутног носиоца",
        MembersStatementForTempHolder:
          "Изјаву свих чланова домаћинства са именовањем привременог носиоца",
        DownloadMembersStatement: "Преузмите формулар за изјаву чланова",
        ConfirmationAccountNumber: "Потврду / Уговор за наменски текући рачун",
        TempHolderStatement:
          "Изјављујем да прихватам да будем уписан у Регистар пољопривредних газдинстава, као привремени носилац породичног пољопривредног газдинства до окончања оставинског поступка и спровођења промене у катастру непокретности.",
        TempHolderFirstName: "Име привременог носиоца",
        TempHolderLastName: "Презиме привременог носиоца",
        TempHoldertPIN: "ЈМБГ привременог носиоца",

        ////// BASIC REQUEST //////
        RequestTitle: "Подношење захтева",
        GeneralRequestTitle:
          "Поднесите захтев Регистру пољопривредних газдинстава ",
        GeneralRequestDesc:
          "Овде можете поднети захтев у слободној форми и доставити жељене прилоге или  документа уз Ваш захтев.",
        GeneralRequests: "Општи захтеви",

        ////// CASE UPDATE //////
        CaseUpdate: "Допуна предмета",
        CaseUpdateRequestTitle: "Допуните предмет или доставите нови поднесак ",
        CaseUpdateRequestDesc:
          "Овде можете допунити захтев или неки други предмет који је тренутно у обради или доставити неку другу врсту поднеска. Потребно је да прво изаберете број предмета на који се поднесак или допуна односи, а затим приложите документа и додате напомену уколико је потребно.",
        CaseNumber: "Број предмета",

        ////// COMPLAINT //////
        ComplaintTitle: "Жалба / одустанак од приговора",
        ComplaintPageTitle:
          "Подношење жалбе или одустанак од приговора на донето решење",
        ComplaintRequestTitle:
          "Поднесите жалбу или одустаните од приговора на донето решење ",
        ComplaintRequestDesc:
          "Овде можете поднети жалбу на донето решење или убрзати процес пуноснажности донетог решења изричитим одустанком од приговора у предвиђеном року. Потребно је да прво изаберете број предмета по ком је донето решење на који се жалба или одустанак односи, а затим приложите документа и додате напомену уколико је потребно.",
        ComplaintType: "Врста поднеска",
        RequestTypeCOMPLAINT: "Жалба",
        RequestTypeCOMPLAINT_WAIVER: "Одустанак од приговора",

        ////// HOLDER //////
        PersonalIdentityNumber: "ЈМБГ",
        FirstName: "Име",
        LastName: "Презиме",
        BirthDate: "Датум рођења",
        BirthPlace: "Место рођења",
        BirthCountry: "Држава рођења",
        Gender: "Пол",
        WorkActivity: "Радна активност",
        Education: "Школовање",
        HolderNotVerifiedInfo: "Носилац газдинства није верификован",
        DisplayPersonalIdentityNumber: "Прикажи ЈМБГ",
        Temporary: "Привремени",
        Permanently: "Стални",

        ////// LEGAL ENTITY //////
        LegalEntityInfoTitleOrdered: "1.1. Подаци о Правном лицу",
        LegalEntitySize: "Величина правног лица",
        RegistrationNumber: "Матични број",
        TaxId: "ПИБ",
        Jbkjs: "ЈБКЈС",
        Name: "Назив",
        NumberOfEmployees: "Број стално запослених",
        DedicatedRegisterNumber: "Број из локалног регистра",
        LegalEntityNotVerifiedInfo: "Правно лице је у процесу провере",
        AgriculturalProduction: "Пољопривредна производња",
        AgriculturalProductsProcessing: "Прерада пољопривредних производа",
        NonAgriculturalProduction:
          "Друге непољопривредне делатности (рурални туризам, стари занати и сл.)",

        LEFormLLC: "Друштво са ограниченом одговорношћу",
        LEFormENTREPRENEUR: "Предузетник",
        LEFormOTHER: "Остало",
        MICRO: "Микро",
        SMALL: "Мало",
        MEDIUM: "Средње",
        LARGE: "Велико",

        ////// HEAD OFFICE //////
        HeadOfficeTitleOrdered: "1.2. Седиште",
        HeadOfficeEdit: "Измени податке о седишту ПГ",
        Street: "Улица",
        NumberLetter: "Број и слово",
        Place: "Место",
        Municipality: "Општина / град",
        PostalCode: "Поштански број",
        HeadOfficeNotVerifiedInfo: `Промењена адрeса седишта је у процесу провере од стране Регистратора.
        Приложене доказе можете погледати и по потреби допунити, кликом на дугме "Приложи документе" у доњем делу секције.`,

        ////// CONTACT //////
        ContactTitleOrdered: "1.3. Контакт подаци",
        ContactEdit: "Измени контакт податке",
        Email: "Е-пошта",
        EmailAddress: "Адреса е-поште",
        EmailHelper: "Адреса на коју ће стизати обавештења",
        EmailNotVerified: "Адреса није верификована.",
        EmailNotVerifiedInfo: `Адреса е-поште није верификована.
        Кликните на дугме "Измени контакт податке", па затим на дугме "Потврди адресу е-Поште" и пратите даља упутства.`,
        VerifyEmail: "Потврди адресу",
        PhoneNumber: "Број мобилног телефона",
        PhoneNumberHelper: "На овај број ће стизати СМС обавештења",
        PhoneNumberNotVerified: "Број мобилног телефона није верификован.",
        PhoneNumberNotVerifiedInfo: `Број мобилног телефона није верификован.
        Кликните на дугме "Измени контакт податке", па затим на дугме "Потврди број телефона" и пратите даља упутства.`,
        VerifyPhoneNumber: "Потврди број",
        EmailConfirmTitle: "Потврда адресе е-поште",
        EmailConfirmDesc:
          "Унесите КОД који Вам је послат на адресу е-поште коју сте унели",
        PhoneConfirmTitle: "Потврда броја телефона",
        PhoneConfirmDesc:
          "Унесите КОД који Вам је послат на број мобилног телефона коју сте унели",
        ResendActivationCode: "Пошаљите ми поново активациони код",
        PreferedDeliveryType: "Начин обавештавања",
        ContactDeliveryTypeINBOX: "еСандуче",
        ContactDeliveryTypeEMAIL: "Емејл",
        ContactDeliveryTypeSMS: "СМС",

        ////// BANK ACCOUNT //////
        BankAccountTitleOrdered: "1.4. Наменски рачун",
        BankAccountEdit: "Измени податке о рачуну",
        AccountNumber: "Број рачуна",
        AccountNumberHelper: "Наменски рачун за уплате из буџета",
        AccountNumberNotVerified: "Власништво над рачуном није потврђено",
        AccountNumberNotVerifiedInfo: `Власништво над наменским рачуном је у процесу провере.
        Приложене доказе можете погледати кликом на дугме "Приложи документе" непосредно испод поља за унос броја рачуна.`,
        BankName: "Назив банке",
        ReferenceNumber: "Позив на број",

        ////// LEGAL REPRESENTATIVE //////
        LegalRepTitleOrdered: "1.5. Подаци о одговорном лицу",
        LegalRepEdit: "Измени податке о одговорном лицу",
        LegalRepresentativeNotVerifiedInfo: "Одгноворно лице није верификовано",

        ////// AUTHORIZED PERSON //////
        AuthorizedPersonsTitle: "Овлашћени заступници",
        AuthorizedPersonsTitleOrdered: "2. Овлашћени заступници",
        AuthorizedPersonEdit: "Измени податке о zastupniku ПГ",
        AuthorizedPersonAdd: "Додај заступника",
        NewAuthorizedPerson: "Додавање новог заступника",
        RemoveAuthorizedPerson: "Брисање заступника",
        RemoveAuthorizedPersonDesc:
          "Да ли сте сигурни да желите да избришете заступника",

        ////// FILES //////
        Document: "Документ",
        DocumentsNeeded: "Потребна документа",
        DocumentsNeededDesc: "Потребно је да приложите следећа документа:",
        UploadAttachments: "Приложи документе",
        RemoveDocument: "Уклони прилог",
        RemoveDocumentDesc: "Да ли сте сигурни да желите да уклоните прилог",
        PCUpload: "Додај са рачунара",
        MobileUpload: "Сликај мобилним телефоном",
        UploadDocument: "Додај прилог",
        justDragAndDropFile: "или само превуци документ са рачунара овде",

        EvidenceTitleHEAD_OFFICE: "Потврда адресе седишта",
        EvidenceDescHEAD_OFFICE: `Да бисте потврдили адресу седишта газдинства, неопходно је да приложите очитану личну карту носиоца, уколико је са чипом, или за личну карту без чипа приложите фотографије или скениране обе стране.
        Документ можете приложити са рачунара или сликајте камером свог мобилног телефона.`,
        EvidenceTitleLEGAL_REPRESENTATIVE: "Потврда одговорног лица",
        EvidenceDescLEGAL_REPRESENTATIVE: `Приложите доказ о овлашћењима лица које се пријављује као одговорно лице пољопривредног газдинства и очитану личну карту одговорног лица уколико је са чипом, или за личну карту без чипа приложите фотографије или скениране обе стране.
        Документ можете приложити са рачунара или сликајте камером свог мобилног телефона.`,
        EvidenceTitleBANK_ACCOUNT: "Потврда власништва над рачуном",
        EvidenceDescBANK_ACCOUNT: `Додајте потврду из банке или уговор о отварању рачуна.
        Приложите са рачунара или сликајте камером свог мобилног телефона`,

        ScanQRInfo: `Скенирајте приказани QR код на свом мобилном уређају и након отварања
        апликације сликајте документ који желите да приложите.`,
        QRExpired: "QR код је истекао!",
        FileUploadSuccess: "Датотека успешно приложена!",
        AddNewFile: "Приложи нову",
        StartCamera: "Покрени камеру",
        UploadFilePhone: "Унесите датотеку са телефона",
        MobileSesionOpened: "Наставите додавање на свом мобилном уређају.",
        SessionExpiresAt: "Сесија истиче",
        FilesCount: "Број додатих докумената",

        ////// MEMBERS //////
        Members: "Чланови",
        MemberAdd: "Додај члана",
        ReplaceMember: "Допуни податке о члану",
        MemberEdit: "Измени податке о члану",
        MemberEditDesc:
          "Измените податке о члану и након тога приложите изјаву",
        NewMember: "Додавање новог члана",
        NewMemberDesc:
          "Унесите податке о члану и након тога приложите изјаву члана",
        MemberNotVerifiedInfo: `Статус пријаљеног члана је у процесу провере.
        Након потврде од стране Регистра моћи ћете да приложите и земљиште члана.`,
        Inactive: "Неактиван",
        SaveMemberData: "Сачувај податке о члану",
        DeleteMember: "Избриши члана",
        DeleteMemberDesc: "Да ли сте сигурни да желите да избришете члана?",
        AddMemberStatement: "Приложи изјаву члана",
        AddMemberLandLeaseStatement: "Изјава члана о уступању земљишта",
        MemberLandNotVerifiedInfo: `Право коришћења над парцелом је у процесу провере и биће додато у Регистар након провере Регстратора.
        Приложене доказе можете погледати кликом на дугме ”Прилози” у дну секције.
        Биљне културе пријављене на овој парцели неће бити уписане у Регистар нити узете у обзир све док право коришћења не буде проверено и потврђено.`,
        AddStatementTitleMEMBER_STATEMENT:
          "Изјава члана домаћинства да јесте члан домаћинства",
        AddStatementDescMEMBER_STATEMENT: `Да би члан домаћинства био уписан у регистар, потребно је да лично да изјаву да је члан породичног пољопривредног домаћинства.
        Изјава се може потписати електронски или у папирној форми и приложити скенирана или фотографисана.`,
        AddStatementTitleMEMBER_LAND_STATEMENT:
          "Изјава члана домаћинства о уступању земљишта",
        AddStatementDescMEMBER_LAND_STATEMENT: `Да би члан домаћинства уступио ПГ лично земљиште у својини или закупу, потребно је да лично да изјаву о томе.
        Изјава се може потписати електронски или у папирној форми и приложити скенирана или фотографисана.`,

        ////// LAND //////
        Land: "Парцела",
        AddMembersLandTitle: "Пријава закупљеног или уступљеног земљишта члана",
        AddMembersLandDesc: `У листи су приказане све катастарске парцеле које члан уступа газдинству.
        Након додавања свих парцела, потребно је приложити изјаву члана о уступању земљишта.`,
        AddMemberLandTitle: "Пријава земљишта члана",
        AddMemberLandTitleName: `Пријава земљишта члана
        {{name}}`,
        AddMemberLandDesc:
          "Одаберите члана чије земљиште пријављујете на газдинству.",
        AddNewLand: "Додај још парцела на листу",
        AddLandToContinue: `Додајте земљиште кликом на "Додај још парцела на листу"`,
        LandMunicipality: "Кат.општина/Општина",
        RemoveLand: "Уклони земљиште",
        RemoveLandDesc:
          "Да ли сте сигурни да желите да уклоните земљиште члана са листе",
        RequiredFieldsMissingLandCultures:
          "Нису унети подаци за сва обавезна поља биљне културе на парцели.",
        ReplaceLand: "Допуни податке о парцели",
        ReplaceLandTitle: "Допуни податке о парцели",
        ReplaceLandDesc:
          "Потребно је да се изврши измена или допуна информација у складу са позивом који Вам је послат. Изаберите власника парцеле {{name}}",
        HolderLand: "Земља носиоца",
        MemberLand: "Земља члана",
        SelectMember: "Изаберите члана",

        MembersLandTitle:
          "Земљиште које је члан уступио пољопривредном газдинству",
        RequiredFieldsMissingMembersLands:
          "Нису унети подаци за сва обавезна поља земљишта које је члан уступио пољопривредном газдинству.",
        UnreportedLandTitle: "Непријављено земљиште у државној својини!",
        UnreportedLandDesc:
          "Постоји {{ha}}ха {{a}}а {{m}}м2 под закупом од државе за које нисте пријавили биљну културу. Сво земљиште у државној својини мора бити пријављено у регистру.",
        LandEdit: "Измени податке о парцели",
        LandAdd: "Додај парцелу",
        LandAddNew: "Додај нову парцелу",
        SaveLand: "Сачувај парцелу",
        HolderLandAdd: "Додај парцелу носиоца",
        MemberLandAdd: "Додај парцелу члана",
        LandsAddAll: "Приложи све парцеле",
        LandDelete: "Уклони целу парцелу",
        DeleteLand: "Уклони парцелу",
        DeleteLandDesc:
          "Обратите пажњу да ли након брисања испуњавате услове за активан статус. Да ли сте сигурни да желите да уклоните парцелу?",
        CadastreNumberShort: "Кат.пар.",
        CadastreNumber: "Број кат. парцеле",
        CadastreMunicipality: "Катастарска општина",
        LandName: "Назив парцеле",
        CadastreCulture: "Катастарска култура",
        Surface: "Површина парцеле",
        UsageBasis: "Основ коришћења",
        UsageBasisUPZ: "Основ коришћења УПЗ",
        LeaseType: "Тип закупа/уступања",
        CadastreCultureRecapitulationTitle: "Преглед пријављене биљне културе",
        SurfaceTitleFormatted: "Површина: ha a m2",
        TotalSurfaceUnderCultures: "Укупно под културама",
        NoReportedCulture: "Без пријављене културе",
        TotalSurfaces: "Укупно земљиште које се обрађује",
        LeaseEndDate: "Трајање закупа до",
        LessorPersonalIdentityNumber: "ЈМБГ закуподавца",
        LessorRegistrationNumber: "Матични број закуподавца",
        LessorIdentificationNumber: "ЈМБГ / МБ закуподавца",
        UsedSurface: "Површина која се обрађује",
        CADASTRE_OWNERSHIP: "Власништво",
        OWNERSHIP_OUT_CADASTRE: "Власништво без РГЗ",
        LEASE: "Закуп/Уступање",
        LEASE_CONSOLIDATION: "Закупљено земљиште из комасације",
        CONSOLIDATION: "Комасација",
        // Add land
        AddLandTitle: "Пријава земљишта носиоца",
        AddLandDesc:
          "Одаберите основ коришћења земљишта које ће бити регистровано у оквиру ПГ.",
        AddExitingLandConfirm: "Желим ли поново унесем исту парцелу",
        AddExistingLandDesc:
          "На вашем газдинству већ постоји уписана парцела са истим подацима, да ли сте сигурни да желите поново да је додате?",
        CadastreLandIsMissingDesc:
          "Уколико у листи не видите катастарску парцелу коју поседујете, вратите се назад а затим додајте ову парцелу путем опције ”Земљиште у својини носиоца које није уређено у Катастру и приложите извод из катастра којим се ово потврђује.",

        AddLandTitleCADASTRE_OWNERSHIP: "Земљиште у својини уписано у Катастар",
        AddMemberLandTitleCADASTRE_OWNERSHIP: `Земљиште у својини уписано у Катастар
        {{name}}`,
        AddLandDescCADASTRE_OWNERSHIP:
          "Одаберите катастарку општину и број катастарске парцеле из падајуће листе и упишите површину која се обрађује а затим додајте парцелу на пољопривредно газдинство.",
        AddLandTitleOWNERSHIP_OUT_CADASTRE:
          "Земљиште у својини које није уређено у Катастру",
        AddMemberLandTitleOWNERSHIP_OUT_CADASTRE: `Земљиште у својини које није уређено у Катастру
        {{name}}`,
        AddLandDescOWNERSHIP_OUT_CADASTRE:
          "Унесите катастарку општину, број катастарске парцеле и унесите укупну површину парцеле и површину удела.",
        AddLandTitleLEASE: "Закупљено или уступљено земљиште",
        AddMemberLandTitleLEASE: `Закупљено или уступљено земљиште
        {{name}}`,
        AddLandDescLEASE:
          "Унесите ЈМБГ закуподавца/власника земљишта, катастарку општину, број катастарске парцеле и унесите укупну површину парцеле и површину дела који се обрађује. Такође, потребно је унети и тип закупа као и период до кад је уговорен закуп/уступање.",
        AddLandTitleLEASE_LEGAL_ENTITY: "Закупљено или уступљено земљиште",
        AddMemberLandTitleLEASE_LEGAL_ENTITY: `Закупљено или уступљено земљиште
          {{name}}`,
        AddLandDescLEASE_LEGAL_ENTITY:
          "Унесите Матични број закуподавца/власника земљишта, катастарку општину, број катастарске парцеле и унесите укупну површину парцеле и површину дела који се обрађује. Такође, потребно је унети и тип закупа као и период до кад је уговорен закуп/уступање.",
        AddLandTitleCONSOLIDATION: "Земљиште из комасације",
        AddMemberLandTitleCONSOLIDATION: `Земљиште из комасације
        {{name}}`,
        AddLandDescCONSOLIDATION:
          "Унесите број решења/извода о расподели комасационе масе, катастарку општину, број катастарске парцеле додељен решењем о комасацији, одаберите катастарску културу (уколико знате) и унесите укупну површину парцеле и површину дела који пољопривредно газдинство обрађује.",
        AddLandTitleLEASE_CONSOLIDATION: `Закупљено земљиште из комасације`,
        AddLandDescLEASE_CONSOLIDATION:
          "Унесите ЈМБГ или Матични број закуподавца/власника земљишта, катастарку општину, број катастарске парцеле и унесите укупну површину парцеле и површину дела који се обрађује. Такође, потребно је унети и тип закупа као и период до кад је уговорен закуп/уступање.",
        AfterThatSubmitEvidence: "Након тога приложите документе:",
        LeaseContract: "Уговор о закупу или уступању",
        CadastreDocument: "Извод из Катастра или Земљишне књиге",
        Attachment2a:
          "Прилог 2а - Изјава одговорног лица у правном лицу које је власник земљишта и закуподавац, односно уступилац земљишта на коришћење",
        ConsolidationDocument: "Решење / Извод о расподели комасационе масе",
        OwnershipOutCadastreDocument:
          "Решење / Извод из катастра или земљишних књига",
        LandEvidenceTitle: "Доказ права коришћења",
        LandEvidenceDesc: `Приложите скениран или фотографисан документ.
        Документ можете додати директно са рачунара или фотографисати директно својим мобилним телефоном.`,

        LandCADASTRE_OWNERSHIPTitle: "У Катастру",
        LandCADASTRE_OWNERSHIPDesc:
          "Земљиште је у власништву и уписано је у Катастар",
        LandOWNERSHIP_OUT_CADASTRETitle: "Није уређено у Катастру",
        LandOWNERSHIP_OUT_CADASTREDesc:
          "Власништво није у потпуности уређено у Катастру",
        LandCONSOLIDATIONTitle: "Из комасације",
        LandCONSOLIDATIONDesc:
          "Земљиште је у власништву по основу процеса комасације",
        LandLEASETitle: "У закупу или уступљено земљиште од физичког лица",
        LandLEASEDesc: "Земљиште је у закупу или уступљено од физичког лица.",
        LandLEASE_LEGAL_ENTITYTitle:
          "У закупу или уступљено земљиште од правног лица",
        LandLEASE_LEGAL_ENTITYDesc:
          "Земљиште је у закупу или уступљено од правног лица.",
        LandLEASE_CONSOLIDATIONTitle: "Закупљено земљиште из комасације",
        LandLEASE_CONSOLIDATIONDesc:
          "Земљиште је у закупу по основу процеса комасације.",
        // Land part
        ShowLandPartEvidence: "Прикажи делове парцеле",
        PartLand: "Делови парцеле",
        PartNumber: "Бр. дела",
        PartSurface: "Површина м2",

        // Statement
        StatementTitle: "Изјава члана домаћинства да јесте члан домаћинства",
        StatementDesc: `Да би члан домаћинства био уписан у регистар, потребно је да лично да изјаву да је члан породичног пољопривредног домаћинства.
        Изјава се може потписати електронски или у папирној форми и приложити скенирана или фотографисана.`,
        StatementTitle_eid: "Изјава у електронској форми",
        StatementDesc_eid: `Члан има еИД - пошаљи изјаву у електронској форми на потписивање.
        Овако потписана, изјава је аутоматски прихваћена.`,
        StatementTitle_attachments: "Изјава у папирној форми",
        StatementDesc_attachments: `Члан нема еИД - изјава ће бити одшампана, скенирана и приложена.
        Овакву изјаву мора проверити надлежни орган.`,
        StatementEvidenceTitle: "Изјава члана домаћинства у папирној форми",
        StatementEvidenceDesc:
          "Ако члан домаћинства нема еИД или други дигитални сертификат приложите:",
        PaperStatementDesc: "Потписану изјаву да је члан домаћинства",
        PaperStatementLandDesc:
          "Потписану изјаву о уступању земљишта члана домаћинства",
        PhotoStatementDesc: "Фотографију или скен обе стране личне карте члана",
        PrintStatementForm: "Одштампајте формулар за изјаву",
        StatementEIDTitle: "Електронска изјава члана домаћинства",
        StatementEIDDesc: `Обавестите члана да ћете му управо послати изјаву на потписивање.
        Време чекања на потпис је 15 минута.`,
        StatementEIDDescPENDING_SIGNATURE: "Изјава је послата на потписивање.",
        StatementEIDDescSIGNED: "Изјава је потписивање.",
        MemberPersonalIdentityNumber: "ЈМБГ члана",
        SendEIDStatement: "Пошаљите изјаву на електронски потпис",
        EvidenceSignStatusPENDING_SIGNATURE: "чека на потпис",
        EvidenceSignStatusSIGNED: "потписано",
        LandNotVerifiedInfo: `Право коришћења над парцелом је у процесу провере и биће додато у Регистар након провере Регстратора.
        Приложене доказе можете погледати кликом на дугме "Прилози" непосредно поред дугмета за измену података`,

        ////// LAND CULTURES //////
        LandCulture: "Биљна култура",
        LandCulturesTitle: "Биљне културе на парцели",
        LandCultureAdd: "Додај биљну културу на парцелу",
        LandCultureEdit: "Измени податке о култури",
        DeleteLandCulture: "Уклони биљну културу",
        DeleteLandCultureDesc:
          "Обратите пажњу да ли након брисања испуњавате услове за активан статус. Да ли сте сигурни да желите да уклоните биљну културу?",
        Culture: "Култура",
        CultureSurface: "Површина под културом",
        NumberOfSeedlings: "Број садница/стабала",
        ProductionType: "Врста производње",
        Irrigation: "Наводњава се",
        HailProtectionSurface: "Противградна заштита на",
        NumberOfThrees: "Број стабала",

        //// ANIMALS //////
        AnimalInfoDesc: `Подаци о фонду животиња се приказују из регистра Управе за ветерину.
        Уколико су подаци непотпуни или нису тачни обратите се надлежној ветеринарској служби како бисте уредили стање у регистру.`,
        CheckAnimalDetails: "Погледај детаље из управе за ветерину",
        HidShort: "ХИД",
        Hid: "Идентификациони број (ХИД)",
        HidHelperLabel: "Хид мора имати 12 карактера",
        AnimalName: "Назив животиња",
        AnimalGroup: "Група животиња",
        AnimalCount: "Broj животиња",
        AnimalCountOrganic: "Број животиња (Органскa производња)",
        AnimalCountConventional: "Број животиња (Конвенционална производња)",
        AnimalStatus: "Статус у оквиру ПГ",
        AnimalProductionType: "Начин узгоја",
        AnimalDescriptionAndCount: "Опис и обим узгоја",
        AnimalCONVENTIONAL: "Конвенционални",
        AnimalORGANIC: "Органски",
        Registered: "Пријављен",
        Unregistered: "Непријављен",
        SHEEP: "Овце и козе",
        PIG: "Свиње",
        CATTLE: "Говеда",
        HORSE: "Коњи, магарци, муле и мазге",
        BIRTS: "Живина и птице",
        BEES: "Пчелиње друштво - кошнице",
        FISH: "Рибе",
        OTHER: "Животиње остале",
        CUSTOM: "Остале животиње",
        RequiredFieldsMissingAnimals: `Није унет број животиња по начину узгоја. 
        Попуните поља "Број - Органски узгој" и "Број - конвенционални узгој"`,
        AnimalsRecapitulationTitle: "Преглед пријављеног животињског фонда",
        EnrolledInUPV: "Уписано у УПВ",
        OrganicFarming: "Органски узгој",
        ConventionalFarming: "Конв. узгој",
        TotalAnimalsCount: "Укупно животиња",
        AddCustomAnimal: "Додај групу осталих животиња",
        DeleteAnimal: "Уклони животињу",
        DeleteAnimalDesc: "Да ли сте сигурни да желите да уклоните животињу?",
        AnimalHidsSection: "Регистровани ХИД-ови у Управи за ветерину",
        AnimalHidsSectionInfo: `Подаци о фонду животиња се преузимају из регистра Управе за ветерину само за већ регистрована Пољопривредна газдинства.
        Молимо да унесете све ХИД-ове регистроване у Управи за ветерину који ће бити пријављени на Ваше газдинство.
        Након добијања Решења о упису ПГ, потебно је да се поново пријавите у систем и проверите податке о животињском фонду.`,
        AnimalHidsSectionButton: "Додај ХИД",
        AnimalHidsList: "Листа ХИД-ова",
        RemoveHid: "Уклони ХИД",
        RemoveHidDesc: "Да ли сте сигурни да желите да уклоните ХИД",

        ////// ERROR //////
        ErrorTitle: "Дошло је до грешке",
        ErrorMessage: "Дошло је до неочекиване грешке. Покушајте поново.",
        ErrorCADASTRIAN_NUMBER_NOT_FOUND:
          "Тражена парцела није пронађена у катастру",
        ErrorFARM_NOT_FOUND: "Пољопривредно газдинство није пронађено",
        ErrorFARM_NOT_READY_FOR_RENEWAL:
          "Пољопривредно газдинство не испуњава услов за обнову",
        ErrorFARM_NOT_RENEWED: "Пољопривредно газдинство није обновљено",
        ErrorUNSUPPORTED_MIME_TYPE: "Формат фајла није подржан",
        ErrorLEGAL_REPRESENTATIVE_CURRENT_USER:
          "Ви сте већ одговорна особа за унети БПГ",
        ErrorLEGAL_REPRESENTATIVE_CHANGE_PHYIS_PERSON:
          "Промена законског заступника могућа је само за правна лица",
        ErrorHOLDER_CHANGE_LEGAL_ENTITY:
          "Привремени носилац је дозвољен само за породична газдинства",
        ErrorFARM_REG_NUMBER_SIZE_12:
          "Број пољопривредног газдинства мора имате 12 цифара",
        ErrorMEMBER_PERSONAL_IDENTITY_NUMBER_EXIST:
          "Члан већ постоји у неком од пољопривредних газдинстава",
        ErrorHOLDER_PERSONAL_IDENTITY_NUMBER_EXIST:
          "Члан је носилац газдинства",
        ErrorUSED_SURFACE_BIGGER_THAN_CADASTRE_SURFACE:
          "Површина удела у парцели већа од површине парцеле",
        ErrorMEMBER_CANNOT_ACT_AS_HOLDER: "Изабрани члан не може бити носилац",
        ErrorLAND_UNVERIFIED_MEMBER:
          "Не може се додати земља члану који није верификован",
        ErrorDATABOX_NOT_FOUND: `Сандуче за доставу документа није пронађено. 
          Молимо прво се пријавите на портал еУправа (http://www.euprava.gov.rs/) а затим поново покушајте`,
        ErrorEDELIVERY_UNAVAILABLE:
          "Сервис за слање тренутно није доступан, молимо покушајте поново.",
        ErrorLAND_PLANT_CULTURE_BIGGER_THAN_LAND_USED_SURFACE:
          "Површина биљних култура на парцели је већа од површине удела у парцели",
        ErrorCADASTRE_NUMBER_WRONG_FORMAT:
          'Број кат. парцеле је погрешног формата. Број може да садржи само цифре, нпр. "123456789" или цифре и подброј након "/" нпр. "123456/12".',
        ErrorBIRTH_DATE_INVALID: "Носилац мора бити старији од 18 година",
        ErrorAT_LEAST_ONE_ACTIVITY_REQUIRED: "/",
        ErrorLEGAL_FORM_OTHER_AGRICULTURAL_PRODUCT_PROCESSING_NOT_ALLOWED: "/",
        ErrorFARMS_IN_REGISTRATION_STATUSES_CAN_NOT_CHANGE_HOLDER:
          "Није могуће променити носиоца док је процес регистрације у току.",
        ErrorLAND_APPROVAL_IN_ADMINISTRATIVE_PROCEDURE:
          "Није могуће мењати парцелу која се налази у поступку верификације.",
        ErrorMEMBER_APPROVAL_IN_ADMINISTRATIVE_PROCEDURE:
          "Није могуће мењати члана који се налази у поступку верификације.",
        ErrorHOLDER_HAVE_TO_BE_MEMBER: "Носиоц мора бити члан газдинства.",
        ErrorCANNOT_RENEW: "Газдинствно не може бити обновљено",
        ErrorBANK_CODE_NOT_FOUND: "Није пронађена банка са унетом шифром.",
        ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_INVOICE_ITEM_SUM:
          "Немате довољно горива на рачунима",
        ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_LAND_PLANT_CULTURE_ALLOWED:
          "Прекорачили сте максималну количину горива за биљну културу на парцели",
        ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_LAND_ALLOWED:
          "Прекорачили сте максималну количину горива за парцелу",
        ErrorREFRACTION_REQUEST_ITEM_LITER_SUM_GT_FARM_ALLOWED:
          "Прекорачили сте максималну количину горива у текућој години",
        ErrorBANK_NOT_ACTIVE: "Банка којој припада рачун није активна",
        ErrorWRONG_BANK_ACCOUNT_NUMBER:
          "Број рачуна није исправан, молимо проверите",
        ErrorWRONG_BANK_CODE: "Банка са кодом {{ bankCode }} не постоји",
        ErrorHOLDER_APPROVAL_ALREADY_EXIST:
          "За ово газдинство већ постоји поднет захтев за промену носиоца који је третнутно у обради",
        ErrorREFRACTION_REQUEST_SENT_TO_HERMES:
          "Није могуће обрисати захтев јер је већ отворен предмет, можете само извршити измену и поновно поднети",
        ErrorHISTORY_AT_DAY_BEFORE_REGISTRATION_DATE:
          "Газдинство за које су тражени подаци није било регистровано у тренутку за који се тражи достављање података",
        ErrorAccessDeniedInfo: `Нисте овлашћени да приступите подацима за тражено пољопривредно газдинство.
        Молимо проверите да ли сте улоговани са исправним креденцијалима.`,

        AppError: `Приступ сервису:
        {{app}}
        тренутно није активан.
        Молимо покушајте касније.`,
        RGZ_UNAVAILABLE: "Катастар непокретности - Републички геодетски завод",
        APR_UNAVAILABLE: "АПР",

        LandReportTitle: "Пријава обраде земљишта по другом основу",
        LandReportDesc:
          "Пријавите обраду земљишта које нисте могли да упишете у РПГ по неком од четири законска основа.",
        LandReportWITHOUT_LEASE_AGREEMENT:
          "Пријава обрађивања земљишта без писаног уговора о закупу",
        LandReportCONSOLIDATION:
          "Пријава обрађивања земљишта из комасације, без решења о расподели комасационе масе",
        LandReportUPZ: "Пријава обрађивања државног земљишта без уговора",

        StartChanges: "Започни промене",
        AdminProcessingRequest: "Админ тренутно обрађује ваш захтев",

        ApprovalStatusAPPROVED: "Обрађено",
        ApprovalStatusREJECTED: "Управни поступак",
        ApprovalStatusPENDING: "Чека на обраду",
        ApprovalStatusDECISION_SEND: "Послато решење",
        ApprovalStatusWAITING_COMPLEMENT: "Чека допуну",
        ApprovalStatusCOMPLEMENTED: "Допуњен",

        WaitingComplementText:
          "Потребно је да се изврши измена или допуна информација у складу са позивом који Вам је послат",

        // SelectMember: "Изаберите члана",
      },
    },
  },
});

export default i18n;
